import { Link, useParams } from 'react-router-dom';
import { Button, Box, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import AppBar from '../../helper/AppBar';
import Hint from '../../helper/AppBar';


export default function SignUp(props) {
    const [loading, setLoading] = useState(0);
    const [inputActive, setInputActive] = useState(false);
    const theme = useTheme();
    const nav = useNavigate();
    const urlParams = useParams();
    const plan = urlParams.plan;

    useEffect(() => {
        document.title = "Friday - Sign up";
        if (![undefined, "basic", "pro", "business"].includes(plan)) {
            nav("/pricing");
        }
        fetch("/api/user/current", {
            method: "GET",
        }).then(resp => resp.json())
            .then(data => {
                if (data.success) {
                    if (data.result !== null) {
                        if (plan) {
                            window.localStorage.productId = plan;
                            nav("/payment");
                        } else {
                            nav("/dashboard");
                        }
                    }
                }
            }).catch(er => {}).finally(_ => {  });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function signUp(ev) {
        ev.preventDefault();

        let f = document.getElementById("signUpForm");

        let mail = f.mail;
        let pass = f.password;
        let first = f.firstName;
        let last = f.lastName;
        let organization = f.organization;

        if ((new RegExp(mail.getAttribute("validation").replace(/^\/+|\/+$/g, ""))).test(mail.value) &&
            (new RegExp(pass.getAttribute("validation").replace(/^\/+|\/+$/g, ""))).test(pass.value) &&
            (new RegExp(first.getAttribute("validation").replace(/^\/+|\/+$/g, ""))).test(first.value) &&
            (new RegExp(last.getAttribute("validation").replace(/^\/+|\/+$/g, ""))).test(last.value)) {
            console.log("FORM IS VALID");
        } else {
            return;
        }

        mail = mail.value;
        pass = pass.value;
        first = first.value;
        last = last.value;
        organization = organization.value;

        setLoading(true);
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lc1IOsdAAAAAEwsT0oTDFpxloWl-kVvsxDSf1Uh', { action: 'submit' }).then(function(token) {
                fetch("/api/user/register", {
                    method: "POST",
                    body: JSON.stringify({
                        mail: mail,
                        password: pass,
                        firstName: first,
                        lastName: last,
                        organization: organization,
                        captcha: token
                    })
                }).then(response => response.json())
                .then(data => {
                    if (data.success) {
                        if (plan) {
                            function capitalizeFirstLetter(string) {
                                return string.charAt(0).toUpperCase() + string.slice(1);
                            }
                            window.localStorage.productId = "plan" + capitalizeFirstLetter(plan);
                            nav("/billing");                            
                        } else {
                            nav("/dashboard");
                        }
                    } else {
                        throw new Error(data.error);
                    }
                }).catch(er => {
                    // TODO
                    alert(er);
                }).finally(_ => {
                    setLoading(false);
                });
            });
        });
    }
    

    return (
        <div>
            <AppBar></AppBar>

            <form id="signUpForm" onSubmit={signUp}>
                <Stack spacing={2} direction="column" sx={{ position: "fixed", 
                                                            top: "0px", left: "0px", right: "0px", bottom: "100px", margin: "auto", 
                                                            height: "fit-content",
                                                            maxWidth: "20vw" }}>

                    <h1 style={{ position: "absolute", top: "-25px", left: "0px", right: "0px", margin: "auto", padding: "0px", textAlign: "center", fontSize: "20px" }}>Jarvis</h1>

                    <h2>Create an account</h2>

                    <br />

                        <TextField 
                            inputProps={{
                                // eslint-disable-next-line
                                validation: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                            }}
                            label="E-Mail" 
                            autoComplete="off"
                            name="mail"
                            autoFocus 
                            required />
                        <TextField 
                            inputProps={{
                                validation: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/
                            }}
                            autoComplete="off"
                            label="Password" 
                            type="password" 
                            name="password"
                            required
                            onFocus={() => { if (!inputActive) setInputActive(true) }}
                            onBlur={() => { if (inputActive) setInputActive(false) }} />
                        { inputActive ? 
                            <Hint sx={{ position: "absolute", right: "-200px", top: "150px", marginTop: "0px" }}>
                                Password must contain at least:<br />
                                <ul style={{ paddingLeft: "25px" }}>
                                    <li> 8 characters </li>
                                    <li> 1 uppercase character </li>
                                    <li> 1 lowercase character </li>
                                    <li> 1 number </li>
                                </ul>
                            </Hint>:
                            <></> }

                        <Box sx={{ display: "flex", justifyContent: "space-between", "& > .MuiTextField-root": { width: "48%" } }}>
                            <TextField 
                                inputProps={{
                                    // eslint-disable-next-line
                                    validation: /^[a-zA-Z ,\.]+$/
                                }}
                                label="First Name" 
                                name="firstName"
                                required />
                            <TextField 
                                inputProps={{
                                    // eslint-disable-next-line
                                    validation: /^[a-zA-Z ,\.]+$/
                                }}
                                label="Last Name" 
                                name="lastName"
                                required />
                        </Box>

                        <TextField 
                            label="Organization Name (optional)" 
                            name="organization" />

                        <Hint>
                            This site is protected by reCAPTCHA and the Google&nbsp;
                            <a style={{ color: "#999" }} href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
                            <a style={{ color: "#999" }} href="https://policies.google.com/terms?hl=en-US" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                            <br /><br />
                            By clicking "Continue" you agree to our&nbsp;
                            <a style={{ color: "#999" }} href="https://fipsi.jarvis.at/privacy?ref=friday" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
                            <a style={{ color: "#999" }} href="https://fipsi.jarvis.at/terms?ref=friday" target="_blank" rel="noreferrer">Terms of Service</a>
                        </Hint>

                        {
                            loading ?
                            <LoadingButton 
                                loading 
                                sx={{ paddingTop: "14.32px !important", paddingBottom: "14.32px !important" }}
                                >
                                    { plan ? "Continue" : "Sign up"}
                            </LoadingButton> : 
                            <Button 
                                type="submit" 
                                sx={{ paddingTop: "14.32px !important", paddingBottom: "14.32px !important" }}
                                >
                                    { plan ? "Continue" : "Sign up"}
                            </Button>
                        }


                    <span style={{ textAlign: "center", fontFamily: theme.typography.fontCode, fontSize: "12px" }}>
                        Already have a Jarvis account?&nbsp;
                        <Link to="/signin" style={{ color: theme.palette.text.primary }}>Sign in</Link>
                    </span>
                </Stack>
            </form>
        </div>
    );
}