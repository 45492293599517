import {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';


export default function SetupIntentResponse(props) {
    const stripe = useStripe();
    const [message, setMessage] = useState(null);
  
    useEffect(() => {
      if (!stripe) {
        return;
      }
  
      // Retrieve the "setup_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );
  
      // Retrieve the SetupIntent
      stripe
        .retrieveSetupIntent(clientSecret)
            .then(({setupIntent}) => {
            // Inspect the SetupIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            switch (setupIntent.status) {
                case 'succeeded':
                    setMessage('Success! Your payment method has been saved.');
                    props.onSuccess && props.onSuccess('Success! Your payment method has been saved.', setupIntent);
                    break;

                case 'processing':
                    setMessage("Processing payment details. We'll update you when processing is complete.");
                    props.onProcessing && props.onProcessing("Processing payment details. We'll update you when processing is complete.", setupIntent);
                    break;

                case 'requires_payment_method':
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setMessage('Failed to process payment details. Please try another payment method.');
                    props.onRequiresPaymentMethod && props.onRequiresPaymentMethod('Failed to process payment details. Please try another payment method.', setupIntent);
                    break;
                
                default:
                    setMessage('An unknown error occured.');
                    break;
          }
        });
    }, [stripe]); // eslint-disable-line react-hooks/exhaustive-deps
  
    return props.quiet ? "" : message;
  }