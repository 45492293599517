import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBar from "components/helper/AppBar";
import IntentsList from "./intents/List";


const rockeet = window.rockeet;


export default function AssistantShow() {
    const [assistant, setAssistant] = useState(null);
    const nav = useNavigate();
    const urlParams = useParams();
    const assistantId = urlParams.id;

    if (!window.localStorage.getItem("assistantToken")) {
        nav("/console?error=noToken");
    }

    rockeet.setToken(window.localStorage.getItem("assistantToken"));

    useEffect(() => {
        const as = new rockeet.Assistant(assistantId);
        as.get().then(a => {
            setAssistant(a);
        }).catch((er,a,b) => {
            console.log(er,a,b);
            alert(er);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <AppBar />
            <Box sx={{ marginTop: "70px", padding: "50px 100px" }}>
                <h1>{assistant?.name || "Loading..."}</h1>

                <IntentsList assistantId={assistantId} token={window.localStorage.getItem("assistantToken")} />
            </Box>

        </>
    );
}