import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useStripe,
  Elements,
} from '@stripe/react-stripe-js';

import { Button, CircularProgress, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import AppBar from 'components/helper/AppBar';
import SetupForm from './SetupForm';
import 'css/payment.scss';


// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


const Subscribe = ({location}) => {
	// Get the lookup key for the price from the previous page redirect.
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const [price, setPrice] = useState(0);
    const [entireLoading, setEntireLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null); // eslint-disable-line no-unused-vars
	const [messages, _setMessages] = useState(''); // eslint-disable-line no-unused-vars
    const [billingInterval, setBillingInterval] = useState(["month", "year"].includes(searchParams.get("interval")) ? searchParams.get("interval") : "month");
    const [userInformation, setUserInformation] = useState(null);
    const [productInformation, setProductInformation] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const urlParams = useParams();
	const nav = useNavigate();
    const plan = "plan-" + urlParams.plan;

    useEffect(() => {
        Promise.all([   fetch("/api/user/current/full"),
                        fetch(`/api/product/${plan}`),
                        fetch("/api/payment/setup", {method:"POST", body:JSON.stringify({})}),
                    ]).then(results => {
                        Promise.all([
                            results[0].json(),
                            results[1].json(),
                            results[2].json(),
                        ]).then(results => {
                            const userRes = results[0];
                            if (!userRes.success)
                            throw new Error(userRes.error);
                            if (userRes.result.hasValidPaymentMethod)
                            nav("/dashboard/plan")
                            setUserInformation(userRes.result);

                            const productRes = results[1];
                            if (!productRes.success)
                            throw new Error(productRes.error);
                            setProductInformation(productRes.result);

                            const paymentRes = results[2];
                            if (!paymentRes.success)
                            throw new Error(paymentRes.error);
                            setClientSecret(paymentRes.result.clientSecret);
                            
                            setBillingIntervalProxy(billingInterval, productRes.result);
                        }).finally(_ => {
                            setEntireLoading(false);
                        });
                    });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const setBillingIntervalProxy = (bi, pi) => {
        setPrice((productInformation || pi).prices.filter(x => x.recurring.interval === bi)[0].unit_amount / 100);
        setBillingInterval(bi);
    }

    const moneyFormat = (number) => {
        return new Intl.NumberFormat(navigator.language, { style: 'currency', 
                                         currency: productInformation
                                            .prices
                                            .filter(x => x.recurring.interval === billingInterval)[0].currency })
                            .format(number);
    }

	// // Initialize an instance of stripe.
	// const stripe = useStripe();
	// const elements = useElements();
    const stripe = useStripe();

	if (entireLoading) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return <CircularProgress sx={{
            position: "fixed",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            margin: "auto"
        }} />;
	}

    
    const uSt = 1.2;
    const annualRebate = 0.15; // 20% off

    // steuerbefreit laut §6 UStg. Österreich (Kleinunternehmerregelung)
    // Tax-exempt according to §6 UStg. Austria (small business regulation)

    // Correct
    let vat = price - (price / uSt);
    let subtotal = billingInterval === "month" ? 0 : price - vat;
    
    let netto = billingInterval === "month" ? price - vat : subtotal / (1-annualRebate);
    let discount = netto * annualRebate;

    let trialEndDate = new Date().addDays(30);
    let firstChargeDate = new Date().addDays(31);

	return (
		<>
			<AppBar></AppBar>

			<div className="main">
                <Stack direction="row" spacing={5}>
					<Stack direction="column" spacing={2}>
						<h2>Payment details</h2>
		
						<div className="card-element">
                            <Elements stripe={stripe} options={{
                                clientSecret: clientSecret
                            }}>
                                <SetupForm />
                            </Elements>

                            {errorMessage && <div>{errorMessage}</div>}
						</div>

                        {
                            userInformation.trialAvailable ? 
                            <div className="trial-dates">
                                Your 30 day trial ends on the { trialEndDate.toLocaleDateString(undefined) } <br />
                                and you'll be first charged on the { firstChargeDate.toLocaleDateString(undefined) }.
                            </div>
                            : ""
                        }

						<Button type="submit" disabled={!stripe}>
							Subscribe
						</Button>


						<div className="message">{messages}</div>
					</Stack>

					<Stack direction="column" spacing={2} className="billing-interval">
                        <h2> {productInformation.product.name} </h2>

                        <p>Billing cycle</p>

                        <ToggleButtonGroup
                            className="billing-interval-toggle"
                            color="primary"
                            exclusive
                            value={billingInterval}
                            onChange={(e) => setBillingIntervalProxy(e.target.value ? e.target.value : e.target.parentElement.value)}
                            >
                            <ToggleButton value="month" color="blue">Monthly</ToggleButton>
                            <ToggleButton value="year" color="green">Annual <span className="benefit">(Save 20%)</span></ToggleButton>
                        </ToggleButtonGroup>

                        <div className="invoice-preview">
                            <p className="position">
                                <span className="top">
                                    <span>{productInformation.product.name}</span>
                                    <span>
                                        { moneyFormat(netto) }
                                    </span>
                                </span>
                                <span className="sub">for { billingInterval === "month" ? "1" : "12" } month{ billingInterval === "month" ? "" : "s" }</span>
                            </p>
                            {
                                billingInterval === "year" ? 
                                <>
                                    <p className="position">
                                        <span className="top">
                                            <span>Discount</span>
                                            <span className="green">
                                                - { moneyFormat(discount) }
                                            </span>
                                        </span>
                                        <span className="sub">{ (100*(annualRebate)).toFixed(0) }% off for subscribing to an annual plan</span>
                                    </p>
                                    <hr />
                                    <p className="position">
                                        <span className="top">
                                            <span>Subtotal</span>
                                            <span>
                                                { moneyFormat(subtotal) }
                                            </span>
                                        </span>
                                        {/* <span className="sub">for subscribing to an annual plan</span> */}
                                    </p>
                                </> : ""
                            }
                            {
                                uSt === 1 ? "" : 
                                <>
                                    <p className="position">
                                        <span className="top">
                                            <span>VAT</span>
                                            <span>
                                                { billingInterval === "month" ? 
                                                    moneyFormat(vat) :
                                                    moneyFormat(vat) }
                                            </span>
                                        </span>
                                        <span className="sub">
                                            <span>
                                                Value Added Tax: { ((uSt - 1) * 100).toFixed(0) }%
                                            </span>
                                        </span>
                                    </p>
                                    <hr />
                                </>
                            }
                            <p className="position">
                                <span className="top">
                                    <span>Total</span>
                                    <span> 
                                        { billingInterval === "month" ? 
                                            moneyFormat(price) :
                                            moneyFormat(price) } 
                                    </span>
                                </span>
                            </p>
                        </div>
					</Stack>
				</Stack>
			</div>
        </>
    )
}


export default Subscribe;



