import { Box, Paper, 
    TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, 
    Typography, Button, Icon } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "components/helper/Loading";


export default function Invoices (props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [invoices, setInvoices] = useState(null);

    const columns = [
        {
            id: "number",
            label: "#",
            format: (value, entry, index) => { return index + 1; }
        },
        {
            id: 'period_start', 
            label: 'Period',
            sortable: true,
            format: (value, entry, index) => {
                if (entry.period_start === entry.period_end) {
                    return moment(entry.period_start * 1000).format("MMM Do, YYYY");
                }
                return moment(entry.period_start * 1000).format("MMM Do, YYYY") + " - " + moment(entry.period_end * 1000).format("MMM Do, YYYY");
            }
        },
        {
            id: 'total', 
            label: 'Amount',
            sortable: true,
            sx: { textAlign: "right" },
            format: (value) => new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(value/100) // amount in cents
        },
        {
            id: 'paid', 
            label: 'Status',
            sortable: false,
            sx: { textAlign: "right", paddingRight: "40px !important" },
            format: (value) => value ? 
                                <Typography fontSize="14px" color="success.main">Paid</Typography> : 
                                <Typography fontSize="14px" color="orange.main">Open</Typography>
        },
        {
            id: 'hosted_invoice_url', 
            label: 'Invoice',
            sortable: false,
            sx: { textAlign: "center", padding: "0px 0px", width: "90px" },
            format: (value) => <Button  onClick={() => { window.open(value, "_blank") }} 
                                        className="text blue" 
                                        sx={{ padding: "7px 16px !important" }}>
                                    <Icon   baseClassName="material-icons-round" 
                                            sx={{ marginRight: "5px", fontSize: "20px" }}>
                                        launch
                                    </Icon>
                                    View
                                </Button>
        },
        {
            id: 'invoice_pdf',
            label: '',
            sortable: true,
            sx:{ textAlign: "left", padding: "0px 0px" },
            format: (value, a, b, c, d) => {
                const onDownload = () => {
                    const link = document.createElement("a");
                    link.download = "invoice.pdf";
                    link.target = "_blank";
                    link.href = value;
                    link.click();
                };

                return  <Button onClick={onDownload} 
                                className="text blue" 
                                sx={{ padding: "7px 16px !important" }}>
                            <Icon   baseClassName="material-icons-round" 
                                    sx={{ marginRight: "5px", fontSize: "20px" }}>
                                file_download
                            </Icon>
                            Download
                        </Button>
            }
        },
    ];

    useEffect(() => {
        fetch("/api/subscription/invoices").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);

            d.result.sort((x,y) => y.created - x.created);
            setInvoices(d.result);
            // setInvoices([]);
        }).catch(er => {}).finally(() => {});
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box sx={props.sx}>
            { invoices ? 
                invoices.length > 0 ?
                <>
                    <Paper elevation={0}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow sx={{
                                    "& th": {
                                        padding: "7px 16px",
                                        border: "2px solid #000",
                                        borderStyle: "none none solid none",
                                    }
                                }}>
                                {columns.map((column) => (
                                    <TableCell  sx={column.sx}
                                                key={column.id}
                                                align={column.align}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column, index) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell sx={{padding: "7px 16px", ...column.sx}} key={column.id} align={column.align}>
                                                {   column.format
                                                    ? column.format(value, row, index)
                                                    : value }
                                            </TableCell>
                                        );
                                        })}
                                    </TableRow>
                                    );
                                })}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={invoices.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            sx={{"& .MuiTablePagination-select": { border: "none" }}}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <Typography fontSize="14px" color="secondary" sx={{marginTop: "15px"}}>
                        Not seeing an invoice? <br />
                        Changes are reflected after 15 minutes. <br />
                        If you have any issues do not hesitate to&nbsp;
                        <Link to="/contact?id=invoice">
                            <Typography fontSize="14px" color="secondary" component="span">contact us.</Typography>
                        </Link>
                    </Typography>
                </>
                : 
                <Typography>
                    <Typography>
                        No invoices yet.
                    </Typography>
                    <Typography sx={{marginTop: "20px"}}>
                        You will never be charged for a free subscription.<br />
                        If you have any questions, do not hesitate to&nbsp;
                            <Link to="/contact">
                                <Typography color="#000" component="span">contact us</Typography>
                            </Link>
                        <br />
                        <br />
                        If you need more requests/month,&nbsp;
                            <Link to="/dashboard/plan?choosePlan=1">
                                <Typography color="#000" component="span">upgrade to a paid plan</Typography>
                            </Link>.<br />
                        Your invoices will show up here.
                    </Typography>
                </Typography>
                : 
                <Loading>
                    Loading invoices...
                </Loading>
            }

        </Box>
    );
}