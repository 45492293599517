import { CircularProgress, Stack, Typography } from "@mui/material";


export default function Loading({ children, sx }) {
    return (
            <Stack direction="column" sx={{ width: "fit-content", height: "100%", alignItems: "center", justifyContent: "center", ...sx }}>
                <CircularProgress />
                <Typography sx={{ marginTop: "20px", fontWeight: "normal !important", padding: "0px !important" }}>
                    { children }
                </Typography>
            </Stack>
    );
}