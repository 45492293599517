import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AppBar from "components/helper/AppBar";
import AssistantList from "./AssistantList";


export default function Console() {
    const [tokens, setTokens] = useState(null);
    const [chosenToken, setChosenToken] = useState(window.localStorage.getItem("assistantToken"));

    useEffect(() => {
        document.title = "Friday - Assistant";

        fetch("/api/user/tokens").then(res => res.json()).then(res => {
            if (res.success) {
                setTokens(res.result);
            } else {
                throw new Error(res.error);
            }
        }).catch(er => {

        });
    }, []);

    const updateChosenToken = token => {
        window.localStorage.setItem("assistantToken", token);
        setChosenToken(token);
    }

    return (
        <>
        <AppBar />
        <Box sx={{ marginTop: "70px", padding: "50px 100px" }}>
            <Stack direction="row" justifyContent="space-between" sx={{
                // width: "400px"
            }}>
                <h1>Assistant</h1>

                <Stack direction="row" sx={{width: "400px"}}>
                    <FormControl fullWidth>
                        <InputLabel id="choose-token">Choose application:</InputLabel>
                        <Select
                            MenuProps={{ disableScrollLock: true }}
                            className="visibleLegend"
                            labelId="choose-token"
                            id="demo-simple-select"
                            value={null}
                            label={(chosenToken && tokens) ? tokens.filter(el => el.id === chosenToken)[0].name : "Choose an application"}
                            onChange={(newValue) => { updateChosenToken(newValue.target.value) }}
                        >
                            {tokens && tokens.map(token => (
                                <MenuItem key={token.id} value={token.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography>{token.name}</Typography>
                                    <Typography fontFamily="JBMono" sx={{ marginLeft: "15px" }} color="secondary" fontSize="0.75rem">{token.id}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>

            { chosenToken && <AssistantList token={chosenToken} /> }
        </Box>
        </>
    );
}
