import { Backdrop, Box, CircularProgress, Tab, Table, TableBody, TableRow, TableCell, useTheme, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const apiUrl = "https://api.friday.fipsi.at";
const leftNavWidth = 400;
const rightCodeWidth = 400;
const rightCodePadding = 30;


export default function DocsStyle ({ left, transparent, boxShadow, onReady }) {
    const theme = useTheme();
    const [openApi, setOpenApi] = useState(null);
    const [ready, setReady] = useState(false);
    const [exampleToken, setExampleToken] = useState("frdy-test");

    useEffect(() => {
        setReady(false);
        fetch(apiUrl + "/openapi.json").then(d => d.json()).then(openApi => {
            setOpenApi(openApi);
            setReady(true);
            onReady && onReady();
        });
        fetch("/api/user/tokens").then(d => d.json()).then(tokens => {
            setExampleToken(tokens.result[0]?.id || "frdy-test");
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function getColorFromMethod(method) {
        switch (method.toUpperCase()) {
            case "GET":
                return theme.palette.green.main;
            case "POST":
                return theme.palette.blue.main;
            case "PUT":
                return "#0000ff";
            case "DELETE":
                return theme.palette.red.main;
            default:
                return "#000000";
        }
    }

    let tags = {};

    return (
        <>
            <Backdrop sx={{ color: '#fff', 
                            zIndex: (t) => t.zIndex.drawer + 1 }}
                        open={!ready}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            { ready && <>
            <Box id="apiDocAbsoluteContainer" sx={{
                width: left ? "calc(100vw - 300px - 20px)" : "unset",
            }}>
                <Box sx={{
                    marginLeft: `calc(${leftNavWidth + 60}px - ${left ? "125px" : "0px"})`,
                }}>
                    {
                        Object.keys(openApi.paths).map(path => {
                            return Object.keys(openApi.paths[path]).map(method => {
                                const example = {};

                                if (openApi.paths[path][method].requestBody) {
                                    Object.keys(openApi.paths[path][method].requestBody.content).forEach(contentType => {
                                        const schemaId = openApi.paths[path][method].requestBody.content[contentType].schema.$ref;
                                        const schema = openApi.components.schemas[schemaId.substring(schemaId.lastIndexOf("/") + 1)];
                                        Object.keys(schema.properties).forEach(property => {
                                            example[property] = schema.properties[property].example;
                                        });
                                    });
                                }

                                if (tags[openApi.paths[path][method].tags[0]] === undefined) {
                                    tags[openApi.paths[path][method].tags[0]] = [];
                                }
                                tags[openApi.paths[path][method].tags[0]].push({
                                    path: path,
                                    method: method.toUpperCase(),
                                });

                                return (
                                    <Box sx={{
                                        display: "grid",
                                        gridTemplateColumns: `1fr ${rightCodeWidth}px`,
                                        gridTemplateRows: "1fr",
                                        gridGap: "75px",
                                        padding: "150px 0px 0px 0px",
                                    }}
                                        id={"api__v1__" + path.replace(/\//g, "_") + "__" + method.toLowerCase()}
                                    >
                                        <Box key={path + method} sx={{ width: "100%" }}>
                                            <Typography fontFamily="JBMono" 
                                                        fontSize="16px"
                                                        component="span">
                                                { path }
                                            </Typography>

                                            <Typography fontFamily="JBMono" 
                                                        fontSize="14px" 
                                                        component="span"
                                                        sx={{ marginLeft: "10px" }}
                                                        color={getColorFromMethod(method)}>
                                                { method.toUpperCase() } 
                                            </Typography>

                                            <Typography fontSize="16px"
                                                        color="secondary"
                                                        sx={{ margin: "15px 0px 17px" }}>
                                                { openApi.paths[path][method].summary }
                                            </Typography>

                                            <Parameters openApi={openApi}
                                                        path={path}
                                                        method={method} />

                                            {/* ENDPOINT DESCRIPTION */}
                                            <Typography fontSize="14px" 
                                                        sx={{ margin: "0px 0px 20px 0px" }} 
                                                        dangerouslySetInnerHTML={{
                                                            __html: openApi.paths[path][method]
                                                                    .description?.replace(/\n\n/g, "<div style='height: 10px'></div>")
                                                                    .replace(/\n/g, "<br />")
                                                                    .replace(/<example[\S\s]+?<\/example>/g, "")
                                                                    .replace(/\`([^\`]+)\`/g, `<code style="background-color:#ededed;color:#060606;padding:2px 5px">$1</code>`) // eslint-disable-line no-useless-escape   
                                                        }}>
                                            </Typography>

                                            {/* CODE EXAMPLES */}
                                            <CodeExamples generateCodeExample={generateCodeExample}
                                                          path={path}
                                                          method={method}
                                                          exampleToken={exampleToken}
                                                          example={example}
                                                          openApi={openApi} />
                                        </Box>

                                        {/* EXAMPLE RESPONSES */}
                                        <ExampleResponses schemas={openApi.components.schemas}
                                                          responses={openApi.paths[path][method].responses}
                                        />
                                    </Box>
                                );
                            })
                        })
                    }
                </Box>

                <ApiNavigation left={left}
                               tags={tags}
                               boxShadow={boxShadow}
                               transparent={transparent}
                               width={leftNavWidth}
                               getColorFromMethod={getColorFromMethod} />

            </Box> 
            
            <Box sx={{ height: "500px" }} />
            </> }
        </>
    );
}


const CodeExamples = ({ generateCodeExample, path, method, exampleToken, example, openApi }) => {
    const [codeExamplesValue, setCodeExamplesValue] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        window.Prism.highlightAll();
    });

    const additionalCodeExamples = [];
    if (openApi.paths[path][method].description) {
        let matches = [...openApi.paths[path][method].description.matchAll(/<example ?(?:([a-z]+)="([^"]+)")? ?(?:([a-z]+)="([^"]+)")?>([\S\s]+?)<\/example>/g)]
        matches.forEach(match => {
            let example = { code: match[5].trim(), }

            if (match[1] !== undefined) { example[match[1]] = match[2]; }
            if (match[3] !== undefined) { example[match[3]] = match[4]; }

            additionalCodeExamples.push(example);
        });
    }

    return (<Box sx={{  width: "100%",
                        position: "relative",
                        "& .MuiButtonBase-root": {
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                            textTransform: "none",
                        },
                        "& .MuiTab-root": {
                            color: "#888",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "transparent",
                        },
                        "& .Mui-selected": {
                            color: theme.palette.blue.main + "!important",
                        },
                        "& .MuiTouchRipple-root": {
                            display: "none",
                        },
                        "& .MuiTabPanel-root": {
                            border: "1px solid #ddd",
                        } 
    }}>
        <TabContext value={codeExamplesValue}>
            <Box>
                <TabList value={codeExamplesValue}
                        onChange={(event, newValue) => { setCodeExamplesValue(newValue) }}
                        sx={{ border: "1px solid #ddd", borderStyle: "solid solid none solid" }}>
                    <Tab label="Web" />
                    <Tab label="Python" />
                    <Tab label="Node.js" />
                    <Tab label="Curl" />
                    {
                        additionalCodeExamples.map((example, index) => {
                            return (
                                <Tab label={example.name} />
                            );
                        })
                    }
                </TabList>
            </Box>
            <TabPanel value={0} index={0} sx={{ overflow: "auto" }}>
                <Typography fontFamily="JBMono"
                    component="code" 
                    sx={{ whiteSpace: "pre-wrap !important", 
                        "&,&>*": { 
                                fontSize: "12px !important", 
                                backgroundColor: "transparent !important" 
                            },
                        }}
                    className="language-js"
                    dangerouslySetInnerHTML={{ 
                        __html: generateCodeExample("javascript", 
                                                    path, 
                                                    method, 
                                                    exampleToken, 
                                                    example, 
                                                    !!openApi.paths[path][method]["requestBody"]?.content["multipart/form-data"],
                                                    openApi) 
                                                }} />
            </TabPanel>
            <TabPanel value={1} index={1} sx={{ overflow: "auto" }}>
                <Typography
                    fontFamily="JBMono"
                    component="code" 
                    sx={{ whiteSpace: "pre-wrap !important", 
                        "&,&>*": { 
                                fontSize: "12px !important", 
                                backgroundColor: "transparent !important" 
                            },
                        }}
                    className="language-py"
                    dangerouslySetInnerHTML={{ __html: generateCodeExample("python", 
                                                                            path, 
                                                                            method, 
                                                                            exampleToken, 
                                                                            example, 
                                                                            !!openApi.paths[path][method]["requestBody"]?.content["multipart/form-data"],
                                                                            openApi) 
                                                                        }} />
            </TabPanel>
            <TabPanel value={2} index={2} sx={{ overflow: "auto" }}>
                <Typography
                    fontFamily="JBMono"
                    component="code" 
                    sx={{ whiteSpace: "pre-wrap !important", 
                        "&,&>*": { 
                                fontSize: "12px !important", 
                                backgroundColor: "transparent !important" 
                            },
                        }}
                    className="language-js"
                    dangerouslySetInnerHTML={{ __html: generateCodeExample("node", 
                                                                            path, 
                                                                            method, 
                                                                            exampleToken, 
                                                                            example, 
                                                                            !!openApi.paths[path][method]["requestBody"]?.content["multipart/form-data"],
                                                                            openApi) 
                                            }} />
            </TabPanel>
            <TabPanel value={3} index={3} sx={{ overflow: "auto" }}>
                <Typography
                    fontFamily="JBMono"
                    component="code" 
                    sx={{ whiteSpace: "pre-wrap !important", 
                        "&,&>*": { 
                                fontSize: "12px !important", 
                                backgroundColor: "transparent !important" 
                            },
                        }}
                    className="language-shell"
                    dangerouslySetInnerHTML={{ __html: generateCodeExample("curl", 
                                                                            path, 
                                                                            method, 
                                                                            exampleToken, 
                                                                            example, 
                                                                            !!openApi.paths[path][method]["requestBody"]?.content["multipart/form-data"],
                                                                            openApi) 
                    }}
                    />
            </TabPanel>
            {
                additionalCodeExamples.map((example, index) => {
                    return (
                        <TabPanel value={index + 4} index={index + 4} sx={{ overflow: "auto" }}>
                            <Typography
                                fontFamily="JBMono"
                                component="code" 
                                sx={{ whiteSpace: "pre-wrap !important", 
                                    "&,&>*": { 
                                            fontSize: "12px !important", 
                                            backgroundColor: "transparent !important" 
                                        },
                                    }}
                                className={"language-" + (example.language || "py")}
                                dangerouslySetInnerHTML={{ __html: example.code }} />
                        </TabPanel>
                    )
                })
            }
        </TabContext>
    </Box>
    );
}

const ApiNavigation = ({ left, tags, boxShadow, transparent, getColorFromMethod, width }) => {
    return (
        <>
            <Box sx={{ position: "fixed",
                       overflow: "auto",
                       left: left ?? "0px",
                       top: "0px",
                       bottom: "0px",
                       height: "100vh",
                       width: width || "300px",
                       padding: "150px 10px 150px 30px",
                       boxSizing: "border-box",
                       boxShadow: boxShadow || "0px 0px 20px 0px #e9e9e9",
                       zIndex: "1",
                       backgroundColor: transparent ? "transparent" : "#fff" }}
            >
                { Object.keys(tags).map((tag, index) => {
                return (
                    <Box>
                        <Typography fontSize="15px"
                                    color="secondary"
                                    fontWeight="400"
                                    sx={{ transform: "translate(-7px)",
                                          padding: index === 0 ? 
                                                    "0px 0px 7px 0px" : 
                                                    ( tag.trim() === "" ? "7px 0px 7px 0px" : "25px 0px 7px 0px") }}
                                    > {tag} </Typography>
                        { tags[tag].map(({ path, method }) => {
                                return (
                                    <Box sx={{
                                        cursor: "pointer",
                                    }}
                                        onClick={() => {
                                            document.getElementById("api__v1__" + path.replace(/\//g, "_") + "__" + method.toLowerCase()).scrollIntoView({ behavior: "smooth", block: "start" });
                                        }}
                                    >
                                        <Typography component="span"
                                                    fontFamily="JBMono" 
                                                    fontSize="14px"> { path.replace(/\/v[0-9]\//, "") } </Typography>
                                        <Typography component="span"
                                                    fontFamily="JBMono" 
                                                    fontSize="11px" 
                                                    color={getColorFromMethod(method)}> { method.toUpperCase() } </Typography>
                                    </Box>
                                );
                            }) }
                    </Box>
                )
                }) }
            </Box>

            <Box sx={{ position: "fixed",
                       right: "0px",
                       top: "0px",
                       bottom: "0px",
                       height: "100vh",
                       width: rightCodeWidth + rightCodePadding + "px",
                       boxSizing: "border-box",
                       zIndex: "-1",
                       backgroundColor: "#2d2d2d",
            }} />
        </>
    );
}

const Parameters = ({ openApi, path, method }) => {
    let numArs = 0;
    if (openApi.paths[path][method].requestBody) {
        numArs = (Object.keys(openApi.paths[path][method].requestBody.content).map(contentType => {
            const schemaId = openApi.paths[path][method].requestBody.content[contentType].schema.$ref;
            const schema = openApi.components.schemas[schemaId.substring(schemaId.lastIndexOf("/") + 1)];
            return Object.keys(schema.properties).length;
        }).reduce((a,b) => a+b, 0) || 0) + (openApi.paths[path][method].parameters?.length || 0);
    }

    return (<>
            { numArs > 0 && <> 
                                <Typography fontSize="16px">Parameters</Typography>
                                <Divider sx={{ margin: "5px 0" }} />
                            </> }

            <Box>
                { openApi.paths[path][method].requestBody && 
                    <>
                        <Typography>JSON Body:</Typography>
                        <Table>
                            <TableBody>
                                { Object.keys(openApi.paths[path][method].requestBody.content).map(contentType => {
                                        const schemaId = openApi.paths[path][method].requestBody.content[contentType].schema.$ref;
                                        const schema = openApi.components.schemas[schemaId.substring(schemaId.lastIndexOf("/") + 1)];
                                        return Object.keys(schema.properties).map(property => {
                                            return  <TableRow sx={{ border: "none !important",
                                                                    "&>td": {
                                                                        padding: "5px",
                                                                    },
                                                                    }}>
                                                        <TableCell sx={{ verticalAlign: "top" }}>
                                                            <Typography component="span" fontWeight="bold" fontSize="14px">
                                                                { property }
                                                            </Typography>
                                                            <Typography component="span" fontWeight="bold" fontSize="8px" color="error" display="inline-block" sx={{transform: "translate(2px, -8px)"}}>
                                                                &nbsp;{ schema.required?.includes(property) ? "(required)" : "" }
                                                            </Typography>
                                                            {
                                                                schema.required?.includes(property) ||
                                                                <Typography component="span" fontSize="12px" color="#8b8b8b" display="inline-block">
                                                                    &nbsp;= { JSON.stringify(schema.properties[property].default) }
                                                                </Typography>
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography fontSize="14px" 
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: schema.properties[property].description?.replace(/\n\n/g, "<div style='height: 5px'></div>")
                                                                                                                            .replace(/\n/g, "<br />")
                                                                                + (schema.properties[property].enum ? 
                                                                                    `<div style='height: 10px'></div>
                                                                                        <span style="font-weight: bold">Allowed values: </span>
                                                                                        ${schema.properties[property].enum.join(', ')}`
                                                                                    : "" ) || ""
                                                                                
                                                                        }}>
                                                            </Typography> 
                                                        </TableCell>
                                                    </TableRow>
                                        });
                                    }) }
                            </TableBody>
                        </Table>
                    </>
                }

                { openApi.paths[path][method].parameters && 
                    <>
                        <Typography>Query Parameters:</Typography>
                        <Table>
                            <TableBody>
                                { openApi.paths[path][method].parameters.map(parameter => {
                                    console.log("PARAMETER", path, method, parameter);
                                    return  <TableRow sx={{ border: "none !important",
                                                            "&>td": {
                                                                padding: "5px",
                                                            },
                                                            }}>
                                                <TableCell sx={{ verticalAlign: "top" }}>
                                                    <Typography component="span" fontWeight="bold" fontSize="14px">
                                                        { parameter.name }
                                                    </Typography>
                                                    <Typography component="span" fontWeight="bold" fontSize="8px" color="error" display="inline-block" sx={{transform: "translate(2px, -8px)"}}>
                                                        &nbsp;{ parameter.required ? "(required)" : "" }
                                                    </Typography>
                                                    {
                                                        parameter.required ||
                                                        <Typography component="span" fontSize="12px" color="#8b8b8b" display="inline-block">
                                                            &nbsp;= { JSON.stringify(parameter.default) }
                                                        </Typography>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontSize="14px" 
                                                                dangerouslySetInnerHTML={{
                                                                    __html: parameter.description?.replace(/\n\n/g, "<div style='height: 5px'></div>")
                                                                                                                    .replace(/\n/g, "<br />")
                                                                        + (parameter.enum ? 
                                                                            `<div style='height: 10px'></div>
                                                                                <span style="font-weight: bold">Allowed values: </span>
                                                                                ${parameter.enum.join(', ')}`
                                                                            : "" ) || ""
                                                                        
                                                                }}>
                                                    </Typography> 
                                                </TableCell>
                                            </TableRow>                                                      
                                    }) }
                            </TableBody>
                        </Table>
                    </>
                }
            </Box>

            { numArs > 0 && <Divider sx={{ margin: "5px 0 25px" }} /> }
        </>
    );
}

const ExampleResponses = ({ schemas, responses }) => {
    const [requestResponseValue, setRequestResponseValue] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        window.Prism.highlightAll();
    });

    return (<Box className="dark" sx={{ position: "relative",
                                        "& .MuiButtonBase-root": {
                                            "&:hover": {
                                                backgroundColor: "transparent",
                                            },
                                            textTransform: "none",
                                        },
                                        "& .MuiTab-root": {
                                            color: "#fff",
                                        },
                                        "& .MuiTabs-indicator": {
                                            backgroundColor: "transparent",
                                        },
                                        "& .Mui-selected": {
                                            color: theme.palette.blue.main + "!important",
                                        },
                                        "& .MuiTouchRipple-root": {
                                            display: "none",
                                        }
                                    }}>
                <TabContext value={requestResponseValue}>
                    <Box>
                        <TabList value={requestResponseValue} 
                                 onChange={(event, newValue) => { setRequestResponseValue(newValue) }}
                                 aria-label="basic tabs"
                        >
                            {
                                Object.entries(responses).map(item => {
                                    return <Tab label={item[1].description} />
                                })
                            }
                        </TabList>
                    </Box>
                    { Object.entries(responses).map((item, index) => {
                        item = item[1];

                        return Object.entries(item.content).forEach((item_, index_) => {
                            let schema;
                            try {
                                schema = schemas[item_[1].schema.$ref.split("/").reverse()[0]];
                            } catch(er) {
                                console.error("Caught:", er);
                                return;
                            }

                            let code = {};

                            Object.entries(schema.properties).forEach((item__, index__) => {
                                let key = item__[0];
                                let value = item__[1].example;
                                code[key] = value;
                            });

                            return <TabPanel value={index} key={index} sx={{ overflow: "auto" }}>
                                        <Typography fontFamily="JBMono"
                                                    component="code" 
                                                    sx={{ whiteSpace: "pre-wrap !important", 
                                                    "&,&>*": { 
                                                        fontSize: "12px !important",
                                                        backgroundColor: "transparent !important",
                                                    } }}
                                                    className="language-json"
                                                    dangerouslySetInnerHTML={{ __html: `${JSON.stringify(code, null, 2).replace(/\\/g, '')
                                                        .replace(/\"\[/g, '[') // eslint-disable-line no-useless-escape
                                                        .replace(/\]\"/g,']') // eslint-disable-line no-useless-escape
                                                        .replace(/\"\{/g, '{') // eslint-disable-line no-useless-escape
                                                        .replace(/\}\"/g,'}')}` // eslint-disable-line no-useless-escape
                                                    }} 
                                        />
                                    </TabPanel>
                                })
                        })
                    }
                </TabContext>
            </Box>
);
}

/* generate code examples based on various factors */
const generateCodeExample = (language, path, method, exampleToken, example, isMultiPart, openApi) => {
    const getUrlParameters = _ => {
        return (openApi.paths[path][method].parameters ? 
            "?" + openApi.paths[path][method].parameters
                .reduce((o,n) => { 
                    if (n.name === undefined) return o; 
                    o.push(`${encodeURIComponent(n.name)}=${encodeURIComponent(n.example)}`); 
                    return o;
                }, []).join("&")
            : "")
    }

    const getUrl = (replacer = "\n\t\t") => {
        return apiUrl + path + getUrlParameters().replace(/(\?|&)/g, replacer + "$1");
    }

    switch(language) {
        case "javascript": 
            return isMultiPart ? 
                `const fileInput = document.querySelector("#yourFileInput") ;
const formData = new FormData();

formData.append("file", fileInput.files[0]);

fetch(\`${ getUrl() }\`, {
    method: "${ method.toUpperCase() }",
    headers: { 
        "Authorization": "${ exampleToken }" 
    },
    body: formData
}).then(d => d.json()).then(response => {
    // Do something with the response
});` : 
`fetch(\`${ getUrl() }\`, {
    method: "${ method.toUpperCase() }",
    headers: { 
        "Authorization": "${ exampleToken }" 
    }${
        method.toLowerCase() === "get" ? "" : `,
    body: JSON.stringify(${JSON.stringify(example, null, 4).replace(/\n/g, "\n    ")})`
    }
}).then(d => d.json()).then(response => {
    // Do something with the response
});`


        case "python":
            return isMultiPart ?
                `import requests

response: dict = requests.post("${ getUrl() }", 
    files={
        "file": open("/path/to/file", "rb")
    }, 
    headers={
        "Authorization": "${ exampleToken }"
    }
).json()` : `import requests

response: dict = requests.${method.toLowerCase()}("${ getUrl() }", 
    json=${JSON.stringify(example, null, 4).replace(/\n/g, "\n    ")}
    headers={ 
        "Authorization": "${ exampleToken }"
    }
).json()

# Do something with the response`;


        case "node":
            return isMultiPart ?
                `const fs = require("fs");
import fetch from "node-fetch";

fetch("${ getUrl() }", {
    method: "${ method.toUpperCase() }",
    headers: { 
        "Authorization": "${ exampleToken }" 
        "Content-Type": "multipart/form-data"
    },
    formData : {
        "file" : fs.createReadStream("/path/to/file")
    }
}).then(d => d.json()).then(response => {
    // Do something with the response
});` :  `import fetch from "node-fetch";

fetch("${ getUrl() }", {
    method: "${ method.toUpperCase() }",
    headers: { 
        "Authorization": "${ exampleToken }",
    },
    body: JSON.stringify(${JSON.stringify(example, null, 4).replace(/\n/g, "\n    ")})
}).then(d => d.json()).then(response => {
    // Do something with the response
});`


        case "curl":
            return isMultiPart ?
                `curl ${ getUrl() } \\
    -X ${ method.toUpperCase() } \\
    -H "Authorization: ${ exampleToken }" \\
    -F file=@/path/to/file` : 
                `curl ${ getUrl() } \\
    -X ${ method.toUpperCase() } \\
    -H "Authorization: ${ exampleToken }" \\
    -d ${JSON.stringify(JSON.stringify(example), null, 4).replace(/\n/g, "\n    ")};`
        

        default: return ""
}
}

