import { Box, Button, Typography, useTheme } from '@mui/material';
import AppBar from 'components/helper/AppBar';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import FAQs from './FAQs';
import AboveFold from './AboveFold';
import ScrollDown from './ScrollDown';
import ModelsBadge from './ModelsBadge';
import BetaDisclaimer from './BetaDisclaimer';

// import waveSVG from './graphics/wave.svg';
import waveDarkSVG from './graphics/wave-dark.svg';
import waveWarnBeginSVG from './graphics/wave-warn-begin.svg';
// import waveWarnEndSVG from './graphics/wave-warn-end.svg';
import waveWarnEnd2SVG from './graphics/wave-warn-end-instant-transition.svg';
import cloudImage from './graphics/cloud.jpg';


export default function LandingPage () {
    const theme = useTheme();

    useEffect(() => {
        document.title = "Friday - Lightning fast AI models for embedded devices";
    }, []);

    return (
        <div>
            <AppBar></AppBar>

            {/* The side location indicator does not really fit into the page 
                    (especially the long titles)...
                Maybe it can come back improved */}
            {/* <SideLocationIndicator /> */}

            {/* Above the fold is a Title, Subtitle, CallToAction and Graphics */}
            <AboveFold />

            {/* Show companies and models that Friday supports */}
            <ModelsBadge />

            {/* A scroll down animation... Maybe remove this but it looks good when small enough */}
            <ScrollDown sxOuter={{ position: "absolute", bottom: "40px", left: "0px", right: "0px", margin: "auto" }} />


            {/* -------------- Here is the fold -------------- */}


            {/* Showcase what problems we solve. TODO: Tasks */}
            <Typography variant="h1" margin="200px 0px 50px" textAlign="center" fontSize="30px" color="green.main" fontWeight="500" dangerouslySetInnerHTML={{
                __html: "We'll help you &horbar;"
            }} />
            
            <Stack padding="50px 350px 25px" id="how-does-friday-help-you">
                <DisplayBox title="Move your AI workload to the cloud"
                            description="Friday takes care of your computational-intensive AI workloads using dedicated servers with NVIDIA GPUs. <br /><br />
                                            You can use our API to easily integrate our AI models into your applications, allowing you to focus on your core business."
                            image={cloudImage}
                            button1={{ label: "See plans", to: "/pricing" }}
                            button2={{ label: "Sign up", to: "/signup" }}
                />
                <DisplayBox title="Utilize commercial AI models"
                            description="Friday provides access to models that are used by the world's top companies to tackle the hardest machine learning challenges. <br /><br />
                                            Including: t5 and BERT from Google, BORT from Amazon and many more"
                            mid={<Box position="relative">
                                <img src="/img/usecase/t5.png" alt="t5" height={200} />
                            </Box>}
                            button1={{ label: "Read the docs", to: "/docs" }}
                            button2={{ label: "Sign up", to: "/signup" }}
                            reverse />
                <DisplayBox title="Perform advanced image analysis"
                            description="Classify objects in images, convert written words to text or identify people in photographs. All from a single API. <br /><br />
                                            Our API provides access to the world's most accurate image recognition models from Meta and co."
                            image={cloudImage}
                            button1={{ label: "Read the docs", to: "/docs" }}
                            button2={{ label: "Sign up", to: "/signup" }}
                />
                <DisplayBox title="Analyze audio recordings"
                            description="Create voice profiles, identify who is speaking at a given moment, detect the emotions of a person and transcribe the spoken words to text (TTS). All from a single API.<br /><br />
                                            Our API provides access to the world's most accurate voice recognition models from Google, SpeechBrain, AlphaCephei and co."
                            image={cloudImage}
                            button1={{ label: "Read the docs", to: "/docs" }}
                            button2={{ label: "Sign up", to: "/signup" }}
                            reverse
                />
                <DisplayBox title="Make text analysis simple"
                            description="Perform extractive question answering, analyze the sentiment of a given text, perform grammar checks and translate texts. All from a single API. <br /><br />
                                            Our API provides access to the world's most accurate text analysis models from Google, Amazon and co."
                            image={cloudImage}
                            button1={{ label: "Read the docs", to: "/docs" }}
                            button2={{ label: "Sign up", to: "/signup" }}
                            last
                />
            </Stack>


            <img src={waveWarnBeginSVG} style={{ width: "100vw", transform: "translate(0px, 5px)" }} alt="" />


            {/* Show a disclaimed, that Friday is not in public beta testing */}
            <BetaDisclaimer />

            <img src={waveWarnEnd2SVG} style={{ width: "100vw", transform: "scale(1.1) translate(0px, 2px)" }} alt="" />

            {/* <Typography variant="h1" marginTop="150px" textAlign="center" fontSize="30px">
                What applications become possible?
            </Typography>

            <Stack id="what-applications-become-possible">
                <Typography>TODO</Typography>
            </Stack> */}

            {/* <img src={waveSVG} style={{ width: "100vw", transform: "translate(0px, 5px)" }} /> */}

            <Box backgroundColor="green.main" padding="50px 0px">
                <Typography variant="h1" textAlign="center" marginBottom="50px" fontSize="30px" color="white.main" fontWeight="500" dangerouslySetInnerHTML={{
                    __html: "Frequently Asked Questions"
                }} />

                <FAQs />

                {/* second CTA */}
                <Box margin="150px auto 100px" width="fit-content">
                    <Button className="green" variant="contained">
                        <Link to="/signup"
                            style={{ textDecoration: "none",
                            fontSize: "18px",
                            color: theme.palette.primary.main }}>
                            Start building for free - no credit card required
                        </Link>
                    </Button>
                </Box>
            </Box>

            <img src={waveDarkSVG} style={{ width: "100vw", transform: "scale(1.2)" }} alt="" />
        </div>
    );
}


function DisplayBox({ title, description, image, reverse, mid, button1, button2, last }) {
    const theme = useTheme();

    return (
        <Stack direction={reverse ? "row-reverse" : "row"} justifyContent="space-between" alignItems="center" marginBottom={ last ? "50px" : "200px" }>
            <Stack direction="column" spacing={4} width="450px">
                <Typography fontSize="25px" fontWeight="400">{title}</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: description }} />

                <Stack direction="row" spacing={3}>
                    { button1 && <Button className="green" variant="contained">
                                        <Link to={button1.to}
                                            style={{ textDecoration: "none",
                                                    fontSize: "15px",
                                                    color: theme.palette.primary.main }}>
                                        {button1.label}
                                    </Link></Button> }
                    { button2 && <Button className="green" variant="text">
                                        <Link to={button2.to}
                                            style={{ textDecoration: "none",
                                                    fontSize: "15px",
                                                    color: theme.palette.primary.main,
                                                    "&::after": {
                                                        transition: ".2s",
                                                        content: "->",
                                                    },
                                                    "&:hover::after": {
                                                        transform: "translateX(10px)",
                                                    } }}>
                                        {button2.label}
                                    </Link></Button> }
                </Stack>
            </Stack>

            {   image &&
                <Box width="500px">
                    <img src={image}
                        style={{ margin: "auto", display: "block", }}
                        height={300}
                        alt="" />
                </Box>
            }

            { mid && mid }

        </Stack>
    )
}