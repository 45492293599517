import { Box } from "@mui/material"
import { useState } from "react";

import i1b from "./img/i1-before-full.jpg";
import i1a from "./img/i1-after-full.png"

const imageList = [
    [i1b, i1a]
];


export default function ImageVisual () {
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // eslint-disable-line no-unused-vars


    return  <Box sx={{  boxSizing: "border-box",
                        position: "relative",
                        width: "600px",
                        height: "407px",
                        display: "block",
                        "& .before, & .after": {
                            transition: ".2s",
                        },
                        "&:hover .before": { opacity: 0 },
            }}>
                <img src={imageList[currentImageIndex][0]} 
                     alt="Before"
                     className="before" 
                     style={{ width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              zIndex: "100",
                              position: "absolute" }} />
                <img src={imageList[currentImageIndex][1]} 
                     alt="After" 
                     className="after"
                     style={{ width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              zIndex: "90",
                              position: "absolute" }} />
            </Box>
}