import { Box, Divider, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ReferenceLine } from "recharts";
import moment from "moment";


const monthsBack = 12;


export default function Usage (props) {
    const [apiTokenUsage, setApiTokenUsage] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState({});

    useEffect(() => {
        fetch("/api/token/usage/full").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);

            let atu = [];
            let year = new Date().getFullYear();
            let month = new Date().getMonth() + 1;

            function pad(num, size) {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }

            for (let i = 0; i < monthsBack; i++) {
                let index = `${year}${pad(month, 2)}`;
                atu.push({
                    "timestamp": new Date(year, month).valueOf(),
                    "month": month,
                    "year": year,
                    "amount": d.result[index],// || Math.floor(Math.random() * (2000 - 100 + 1)) + 100,
                    "amountPrediction": 0,
                });
                month --;
                if (month === 0) {
                    month = 12;
                    year --;
                }
            }
            atu.sort((a,b) => { let d = a.year - b.year; if (d === 0) { return a.month - b.month } return d })
            atu[atu.length - 1]["amountPrediction"] = atu[atu.length - 1]["amount"] / new Date().getDate() * new Date(atu[atu.length - 1].year, atu[atu.length - 1].month, 0).getDate();
            atu[atu.length - 1]["amountPrediction"] -= atu[atu.length - 1]["amount"]
            setApiTokenUsage(atu);
        }).catch(er => {console.error(er)}).finally(_ => {});

        fetch("/api/subscription/current").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);

            setCurrentSubscription(d.result);
        }).catch(er => { console.error(er) }).finally(_ => {});
    }, []);

    const roundUp = (num, to=100) => {
        return Math.ceil(num/to)*to;
    }

    if (!(currentSubscription.id && apiTokenUsage.length))
        return "";

    return <Box>
                <BarChart 
                    width={props.w || props.width || 700} 
                    height={props.h || props.height || 250}
                    data={apiTokenUsage}
                    >

                    <defs>
                        <linearGradient
                            id="amount"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0" stopColor="#ff3f3f" />
                            <stop offset=".3" stopColor="#ffc53f" />
                            <stop offset="1" stopColor="#0abd75" />
                        </linearGradient>
                    </defs>

                    {(typeof props.grid === "undefined" || !!props.grid) && 
                        <CartesianGrid  strokeDasharray="3 3"
                                        vertical={false}    />  }
                        <XAxis  dataKey="timestamp"
                                fontSize={12} 
                                axisLine={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                tickLine={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                tick={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                tickFormatter={val => { return moment(val).format("MMM. YY'") }} />
                        <YAxis  fontSize={12}
                                axisLine={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                tickLine={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                tick={!(typeof props.yAxis === "undefined" || !!props.yAxis)}
                                domain={[0, dataMax => {
                                        return roundUp(dataMax > currentSubscription.info.requests
                                                            ? dataMax * 1.1 
                                                            : currentSubscription.info.requests * 1 , 100)}]} />   

                    <Tooltip
                        isAnimationActive={false}
                        content={({ active, payload, label }) => {
                            if (active && payload) {
                                return <Box sx={{
                                    backgroundColor: "rgba(255,255,255,0.9)",
                                    boxShadow: `0px 0px 5px 2px rgba(0,0,0,0.15)`,
                                    padding: "5px 10px",
                                    borderRadius: "3px",
                                }}>
                                    <Typography >{ moment(payload[0]?.payload?.timestamp).format("MMMM YYYY") }</Typography>
                                    <Divider sx={{margin: "5px 0"}} />
                                    <Typography fontSize={14}>
                                        { payload[0]?.payload?.amount } total requests
                                        { payload[0]?.payload?.amountPrediction > 0 ? 
                                        <>  &nbsp;
                                            <Typography fontSize={12} color="#aaa" component="span">
                                                *incomplete
                                            </Typography>
                                            <Typography fontSize={12}>
                                            {`~ ${Math.ceil(payload[0]?.payload?.amountPrediction+payload[0]?.payload?.amount)} requests predicted`}
                                            </Typography>
                                        </> :
                                        "" }
                                    </Typography>
                                </Box>;
                            }
                            return null;
                        }}
                     />

                    <ReferenceLine 
                        // label="Requests included in plan" 
                        stroke="red"
                        strokeDasharray="5 5"
                        segment={[
                                { x: apiTokenUsage[0].timestamp,                        y: currentSubscription.info.requests }, 
                                { x: apiTokenUsage[apiTokenUsage.length - 1].timestamp, y: currentSubscription.info.requests }]} 
                        />
                    <Bar 
                        dataKey="amount"
                        fill="url(#amount)"
                        stroke="url(#amount)"
                        strokeWidth={1}
                        stackId="a"
                        radius={0}
                        />
                </BarChart>
        </Box>;
}