import { AppBar, Icon, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'css/index.scss';


function CustomLink (props) {
    const theme = useTheme();

    return (
        <Link {...props} style={{ textDecoration: 'none', color: theme.palette.text.primary, 
                                    fontFamily: props.l ? theme.typography.fontPrimary : theme.typography.fontCode, 
                                    fontSize: props.l ? "22px" : "16px", display: "block", ...props.style, ...props.sx }}>
            {props.children}
        </Link>
    )
}

function EmptyLink (props) {
    const theme = useTheme();

    return (
        <span {...props} style={{ ...{ textDecoration: 'none', color: theme.palette.text.primary, cursor: "pointer", 
                                        fontFamily: props.l ? theme.typography.fontPrimary : theme.typography.fontCode, 
                                        fontSize: props.l ? "22px" : "16px", display: "block" }, ...props.style, ...props.sx }}>
            {props.children}
        </span>
    )
}


export default function StyledAppBar () {
    const nav = useNavigate();
    const theme = useTheme();
    const [loggedIn, setLoggedIn] = useState(false);

    const authenticatedPages = ["/dashboard", "/billing"];
    const needAuth = authenticatedPages.includes(window.location.pathname)

    useEffect(() => {
        fetch("/api/user/current", {
            method: "GET",
        }).then(resp => resp.json())
            .then(data => {
                if (data.success) {
                    if (data.result !== null) {
                        if (!loggedIn) setLoggedIn(true);
                    } else {
                        if (needAuth) nav("/signin");
                        if (loggedIn) setLoggedIn(false);
                    }
                }
            }).catch(er => {}).finally(_ => {  });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    function signOut() {
        fetch("/api/user/logout", {
            method: "POST",
            body: JSON.stringify({})
        }).then(resp => resp.json())
            .then(data => {
                if (data.success) {
                    nav("/");
                }
            }).catch(er => {}).finally(_ => {  });
    }

    return (
        <AppBar position="static" sx={{ top: "0px", zIndex: 99,
                                        position: "fixed",
                                        WebkitBackdropFilter: "blur(5px)",
                                        backdropFilter: "blur(5px)",
                                        background: "rgba(255, 255, 255, 0.8)",
                                        color: "#000",
                                        boxShadow: "none",
                                        border: "0px solid #E7EBF0",
                                        borderBottomWidth: "thin",
                                        padding: "10px 15px 10px 15px",
        }}>
            <Toolbar variant="dense" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction="row" spacing={2}>
                    <CustomLink to="/" l={1}>
                        <Icon baseClassName="material-icons-round">
                            rocket_launch
                        </Icon>
                    </CustomLink>
                    <Typography background="green.main" sx={{
                        background: theme.palette.green.main,
                        padding: "3px 10px",
                        fontSize: "13px",
                        display: "block",
                        height: "fit-content",
                        color: theme.palette.white.main,
                        margin: "auto 0 auto 30px",
                        cursor: "pointer",
                    }} onClick={() => {}}>Preview Version (Alpha)</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{
                            "& > a, & > span": {
                                display: "block",
                                marginLeft: "50px",
                                transition: ".2s",
                            },
                            "& > a:hover, & > span:hover": {
                                opacity: 0.7
                            }
                            }}>
                    <CustomLink to="/pricing">Pricing</CustomLink>
                    <CustomLink to="/docs">API Docs</CustomLink>
                    { loggedIn ?
                        <>
                            <CustomLink to="/dashboard">Dashboard</CustomLink>
                            <EmptyLink onClick={signOut}>Log out</EmptyLink>
                        </>
                        : 
                        <>
                            <CustomLink to="/signup">Signup</CustomLink>
                            <CustomLink to="/signin">Login</CustomLink>
                        </> }
                </Stack>
            </Toolbar>
        </AppBar>
    )
}; 
