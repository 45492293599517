import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

import AppBar from "components/helper/AppBar";
import Plans from "./Plans";


export default function Pricing() {
    useEffect(() => {
        document.title = "Friday - Pricing";
    }, []);

    return (
        <>
            <AppBar></AppBar>
            
            <div id="pricing">
                <Typography component="h1" textAlign="center" margin="200px 0px 30px" fontSize="35px">Projects of all sizes create<br />intelligent Apps with Friday.</Typography>
                <Typography component="p" textAlign="center" fontSize="17px">
                    Pick a plan that fits your needs. We scale up with you.
                </Typography>

                <Box sx={{ width: "800px", margin: "50px auto 0" }}>
                    <Plans />
                </Box>
            </div>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </>
    );
}