import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/helper/Loading";
import PricingOption from "./PricingOption";


export default function Plans (props) {
    const nav = useNavigate();
    const [plans, setPlans] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const style = {
        width: "100%",
        height: "100%",
        padding: "10px 35px 20px",
    };
    
    useEffect(() => {
        fetch("/api/plans").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);
            setPlans(d.result);
        });

        fetch("/api/subscription/current").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);
            setSubscription(d.result);
        });
    }, []);

    if (!plans || !subscription)
        return <Loading>Loading plans...</Loading>

    return <Stack spacing={1} direction="row" sx={{ 
                justifyContent: "space-between", 
                marginTop: "50px",
                "& .pricing-card:not(:last-child)": {
                    border: "1px solid #ccc !important",
                    borderStyle: "none solid none none !important",
                }
            }}>
            {   plans.map((plan, index) => {
                    return <PricingOption
                        sx={style}
                        current={subscription.id === plan.id}
                        name={plan.name}
                        fee={plan.price}
                        price={plan.additionalRequest}
                        requests={plan.requests}
                        buttonText={props.texts?.[index] || "Sign up"}
                        onClick={ props.handlers?.[index] || function() { nav("/signup") } }
                        buttonColor={index === 0 ? "green" : "blue"} />;
                })  }
        </Stack> ;
}