// Create new empty React functional component:

import { Box } from "@mui/material";
import { useState } from "react";
import ApiDoc from "components/pages/docs/DocsStyle";


export default function DashboardDocs() {
    const [ready, setReady] = useState(false);

    return  <Box sx={{ width: "calc(100vw - 250px - 250px)" }}>
                <h1 style={{
                    marginLeft: "220px",
                    display: ready ? "block" : "none",
                }}>API Documentation</h1>

                <ApiDoc left="225px"
                        transparent
                        boxShadow="none"
                        onReady={() => setReady(true)}
                />
            </Box>
};
