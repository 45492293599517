import { Link, Box, Stack, Typography, Icon, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';


export default function DashboardHome() {
    const [user, setUser] = useState([]);


    useEffect(() => {
        fetch("/api/user/current").then(d => d.json()).then(d => {
            if (d.success)
                setUser(d.result)
        })
    }, []);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12)
            return "Good Morning";
        else if (hour < 18)
            return "Good Afternoon";
        else
            return "Good Evening";
    }

    return (
        <Box>
            <h1> { getGreeting() }{ user?.firstName ? `, ${user.firstName}!` : "!" } </h1>

            <Grid container spacing={2} sx={{ marginTop: "35px" }}>
                <Grid item>
                    <DashboardLink
                        to="/examples"
                        header="First time?"
                        icon="token"
                        bottom="Check out some examples"
                        color="#4287f5"
                    />
                </Grid>
                <Grid item>
                    <DashboardLink
                        to="/dashboard/apps"
                        header="Getting started?"
                        icon="add_circle"
                        bottom="Register a new app"
                        color="#0abd75"
                    />
                </Grid>
            </Grid>

        </Box>
    );
}

const DashboardLink = (props) => {
    return <Link 
                to={props.to} 
                component={RouterLink} 
                underline="none">
                <Stack
                    direction="column" 
                    spacing={1}
                    sx={{ 
                            width: "200px",
                            border: "2px solid #000",
                            backgroundColor: "#fff",
                            padding: "10px 20px",
                            transition: ".2s",
                            position: "relative",
                            "&:hover": {
                                boxShadow: `6px 6px 0 0 ${props.color}`,
                            },
                            "&:hover .icon": {
                                color: props.color
                            }
                        }}
                        >
                    <Box 
                        sx={{
                            borderRadius: "50%",
                            width: 20,
                            height: 20,
                        }}
                        >
                        <Icon 
                            baseClassName="material-icons-round"
                            className="icon"
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                                margin: "auto",
                                fontSize: 26,
                                transition: ".2s",
                            }}>{props.icon}</Icon>                
                    </Box>
                    <Typography 
                        textAlign="center" 
                        sx={{
                            marginTop: "15px !important",
                            opacity: 0.7,
                            fontSize: 13,
                        }}>{props.header}</Typography>
                    <Typography textAlign="center" sx={{marginTop: "0px !important"}}>{props.bottom}</Typography>
                </Stack>
            </Link> ;
}