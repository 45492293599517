import { Box, CircularProgress, Stack, Typography, Icon, Table, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import moment from "moment";
import Loading from "components/helper/Loading";


export default function DashboardFiles() {
    const nav = useNavigate();
    const [files, setFiles] = useState(null);
    const [tokens, setTokens] = useState(null);
    const [showFiles, setShowFiles] = useState(null);
    const [fileUploads, setFileUploads] = useState(0);

    const loadFiles = () => {
        setFiles(null);
        Promise.all([
            fetch("/api/files"),
            fetch("/api/user/tokens"),
        ]).then(ds => {
            Promise.all([
                ds[0].json(),
                ds[1].json(),
            ]).then(ds => {
                let d = ds[0];
                if (!d.success)
                    throw new Error(d.error);

                d.result.forEach((el) => {
                    el.files.map(_ => { _.id = el.id + "/" + _.id; return _ })
                });
                setFiles(d.result);

                d = ds[1];
                if (!d.success)
                    throw new Error(d.error);
                setTokens(d.result);
            });
        });
    }

    useEffect(loadFiles, []);

    const toggleSetShowFiles = id => { setShowFiles(showFiles === id ? null : id) };
    const bytesToSize = bytes => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes <= 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
    const uploadFiles = event => {
        function postFile(file, callback) {
            var formdata = new FormData();
            formdata.append('file', file);
            var request = new XMLHttpRequest();
            request.upload.addEventListener('progress', function (e) {
                var fileSize = file.size;
                if (e.loaded <= fileSize) {
                    var percent = Math.round(e.loaded / fileSize * 100);
                    callback(file, percent)
                } 
                if(e.loaded === e.total){
                    callback(file, 100);
                }
            });

            request.open('post', '/api/files/' + event.target.dataset.id);
            request.timeout = 0;
            request.send(formdata);
        }

        let _fups = {};
        [...event.target.files].forEach(file => {
            _fups[file.name] = {
                file, progress: 0
            }
        });
        window.$fups = _fups;
        setFileUploads(fileUploads => fileUploads + 1);
        
        [...event.target.files].forEach(file => {
            let fn = (file, progress) => {
                window.$fups[file.name] = { file, progress };
                setFileUploads(fileUploads => fileUploads + 1);
            }
            postFile(file, fn);
        });
        
        const interval = setInterval(() => {
            if ([...Object.keys(window.$fups)].map(key => window.$fups[key].progress).reduce((a,b) => a+b-100, 0) === 0) {
                clearInterval(interval);
                loadFiles();
                setTimeout(_ => {
                    if ([...Object.keys(window.$fups)].map(key => window.$fups[key].progress).reduce((a,b) => a+b-100, 0) === 0) {
                        window.$fups = null;
                        setFileUploads(0);
                    }
                }, 2000);
            }
        }, 100);
    }

    const columns = [
        {
            id: 'contentType', 
            label: 'Content Type',
            minWidth: "70px",
            maxWidth: "70px",
            format: val => {
                let icon = "insert_drive_file";
                if (val.startsWith("audio"))
                    icon = "music_note";
                else if (val.startsWith("video"))
                    icon = "movie";
                return <Icon    baseClassName="material-icons-round" 
                                sx={{   fontSize: "17px", 
                                        margin: "auto 0px auto 50px", 
                                        display: "block" }}>{icon}</Icon>;
            }
        },
        {
            id: 'originalFilename', 
            label: 'File Name',
            minWidth: "auto",
            maxWidth: "auto",
            sortable: false,
            format: (val,a,b,) => {
                let maxLen = 30;
                return <Typography 
                            fontSize="14px"
                            >{val.length > 30 ? val.substring(0, maxLen) + "..." : val}</Typography>;
            }
        },
        {
            id: 'createdAt', 
            label: 'Created',
            minWidth: "160px",
            maxWidth: "160px",
            format: val => {
                return <Typography color="secondary" fontSize="14px">
                            {moment(val*1000).format("MMM. Do YYYY, HH:mm")}
                        </Typography>;
            }
        },
        {
            id: 'size', 
            label: 'Size',
            minWidth: "75px",
            maxWidth: "75px",
            format: val => {
                return <Typography color="secondary" fontSize="14px" textAlign="right">
                            {bytesToSize(val)}
                        </Typography>;
            }
        },
        {
            id: 'fileId', 
            label: 'Download ID',
            minWidth: "100px",
            maxWidth: "100px",
            sortable: false,
            format: (val, col) => {
                return <Button className="text blue" onClick={() => {
                    window.open("/api/files/" + col.token + "/" + col.fileId, "_blank");
                }}>
                            <Icon   baseClassName="material-icons-round" 
                                    sx={{   fontSize: "17px",
                                            marginRight: "5px"  }}>file_download</Icon>
                            <Typography fontSize="14px">Download</Typography>
                        </Button>
            },
            onClick: (ev) => {
                console.log(ev.target);
            }
        },
        {
            id: 'fileId', 
            label: 'Delete ID',
            minWidth: "100px",
            maxWidth: "100px",
            sortable: false,
            format: (val, col) => {
                return <Button className="text red" onClick={(event) => {
                        fetch("/api/files/" + col.token + "/" + col.fileId, {
                            method: "DELETE",
                            body: JSON.stringify({})
                        }).then(d => d.json()).then(d => {
                            if (d.success) {
                                try {
                                    setFiles(files => files.map(f => {
                                        f.files = f.files.filter(x => x.id !== val);
                                        return f;
                                    }));
                                } catch (er) {
                                    console.error(er);
                                }
                            }
                        }).catch(er => { console.log("error2", er); alert("Failed to delete a file!") }).finally(_ => {});
                    }}>
                            <Icon baseClassName="material-icons-round" 
                                    sx={{   fontSize: "17px",
                                            marginRight: "5px"  }}>delete</Icon>
                            <Typography fontSize="14px">Delete</Typography>
                        </Button>
            },
        },
    ];

    return <Box sx={{ width: "calc(100vw - 250px - 250px)" }}>
                <h1>Files</h1>

                <Box sx={{ marginBottom: "20px" }}>
                    <Typography fontSize="14px" color="secondary">
                        Below you'll find a listing of all uploaded files. <br />
                        You may upload files manually or through the API. 
                        <br /><br />
                        Files are grouped by the token they're uploaded from. <br />
                        One token cannot access files from another token, even if they belong to the same account.
                    </Typography>

                    <Button className="blue" onClick={() => { nav("/docs/file") }} sx={{ marginTop: "20px" }}>
                        How to use files with the API
                    </Button>
                </Box>

                <Box sx={{ width: "100%" }}>
                    {   !files || !tokens ? 
                            <Box sx={{ width: "200px", marginTop: "30px !important" }}>
                                <Loading>Loading files...</Loading>
                            </Box>
                            :
                            files.map(el => (
                                <>
                                    <Stack  direction="row" 
                                            onClick={() => { toggleSetShowFiles(el.id) }}
                                            sx={{
                                                padding: "10px 20px",
                                                backgroundColor: "#fff",
                                                cursor: "pointer",
                                                width: "100%",
                                                justifyContent: "space-between",
                                            }}
                                            >
                                        <Stack direction="row">
                                            <Icon baseClassName="material-icons-round">{ showFiles === el.id ? "folder_open" : "folder" }</Icon>
                                            <Typography sx={{ minWidth: "400px", marginLeft: "20px" }}>
                                                <Typography component="span" color="primary" fontSize="14px">
                                                    { tokens.filter(_ => _.id === el.id)[0].name }
                                                </Typography>
                                                <Typography component="span" color="secondary" fontSize="12px" fontFamily="JBMono">
                                                    &nbsp;&nbsp;({el.id})
                                                </Typography>
                                            </Typography>
                                        </Stack>

                                        <Stack direction="row">
                                            <Button 
                                                className="text green" 
                                                sx={{
                                                    marginRight: "20px",
                                                    padding: "0px 14px !important",
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    transform: "translate(0px, -1px)",
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    document.querySelector(`input[type=file][data-id=${el.id}]`).click();
                                                }}
                                            >
                                                <input  type="file" 
                                                        multiple 
                                                        data-id={el.id} 
                                                        hidden 
                                                        style={{display: "hidden", width: "0.1px", height: "0.1px", position: "fixed", left: "-10000px", top: "-10000px"}} 
                                                        onChange={uploadFiles}
                                                    />
                                                <Icon 
                                                    baseClassName="material-icons-round" 
                                                    sx={{   fontSize: "17px",
                                                            marginRight: "5px"  }}>file_upload</Icon>
                                                <Typography fontSize="14px">Upload file</Typography>
                                            </Button>
                                            <Typography textAlign="right" fontSize="14px" color="secondary">
                                                Files: {el.files.length}
                                            </Typography>
                                            <Typography textAlign="right" fontSize="14px" color="secondary" sx={{marginLeft: "20px"}}>
                                                Total Size: {bytesToSize(el.files.map(f => f.size).reduce((partialSum, a) => partialSum + a, 0))}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Table>
                                        <TableBody sx={{
                                            display: showFiles === el.id ? "table" : "none",
                                            width: "100%",
                                            position: "relative",
                                            "&::before": {
                                                content: "''",
                                                height: "calc(100% - 20px)",
                                                width: "2px",
                                                backgroundColor: "#ddd",
                                                display: "block",
                                                position: "absolute",
                                                left: "30px",
                                                top: "6px",
                                            }
                                        }}>
                                            { el.files.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" data-row-id={row.id} tabIndex={-1} sx={{
                                                        "&>.MuiTableCell-root": {
                                                            padding: "5px",
                                                        },
                                                        "&[data-hidden]": {
                                                            display: "none"
                                                        }
                                                    }}>
                                                    { columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell  align={column.align} 
                                                                        sx={{
                                                                            minWidth: column.minWidth,
                                                                            maxWidth: column.maxWidth,
                                                                        }}
                                                                        onClick={column.onClick ?? column.onClick}>
                                                                { column.format
                                                                    ? column.format(value, row, column)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </>
                            ))
                    }
                </Box>
            
                <Box sx={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px",
                    display: !!fileUploads ? "block" : "none",
                    backgroundColor: "#fff",
                    padding: "10px 20px",
                }}>
                    {  !!fileUploads && [...Object.keys(window.$fups)].map(key => (
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography fontSize="14px" color="primary">
                                {window.$fups[key].file?.name}
                            </Typography>
                            <Stack direction="row" alignItems="center">
                                <CircularProgress 
                                    sx={{ width: "20px !important", height: "20px !important", marginLeft: "30px !important" }}
                                    variant="determinate" 
                                    color={window.$fups[key].progress === 100 ? "green" : "blue"} 
                                    value={window.$fups[key].progress} />
                                <Typography fontSize="14px" color="secondary" textAlign="right" sx={{ width: "45px" }}>
                                    {window.$fups[key].progress}%
                                </Typography>
                            </Stack>
                        </Stack>
                    ))
                    }
                </Box>
            </Box>;
}