import { Box } from "@mui/material"


export default function Sidebar (props) {
    return ( 
        <Box className="sidebar" sx={{...{
            position: "fixed",
            left: "0px",
            top: "0px",
            bottom: "0px",
            height: "100vh",
            width: "225px",
            padding: "150px 0px",
            boxSizing: "border-box",
            boxShadow: "0px 0px 20px 0px #e9e9e9",
            zIndex: "1",
        }, ...props.style, ...props.sx}}>
            {props.children}
        </Box>
    )
}