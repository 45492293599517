import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppBar from "components/helper/Loading";
import Loading from "components/helper/Loading";


export default function Contact ({ component }) {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const [defaultEmail, setDefaultEmail] = useState(null);
    const [instruction, setInstruction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, _setMessage] = useState(null);
    const setMessage = (message) => {
        _setMessage(message);
        // setTimeout(() => {
        //     _setMessage(null);
        // }, 5000);
    };

    const theme = useTheme();

    useEffect(() => {
        document.title = "Friday - Contact us";

        fetch("/api/user/current").then(d => d.json()).then(d => {
            setDefaultEmail(d.result?.mail);
        })
    }, []);

    if (defaultEmail === null)
        return <Box sx={{ width: "250px" }}>
                    <h1>Contact</h1> 
                    <Box sx={{marginTop: "50px"}}> 
                        <Loading>
                            Loading user information...
                        </Loading> 
                    </Box> 
                </Box>;

    const topics = [ { description: "I have special requirements and need a custom plan",
                       instruction: "Explain your requirements in the message field below. <br /> We will contact you as soon as possible.",
                       id: "special-requirements",
                       modeAvailable: 0 },
                     { description: "I need help with an invoice",
                       instruction: "Please provide the invoice number in the message field below. <br /> We will contact you as soon as possible.",
                       id: "invoice",
                       modeAvailable: 2 },
                     { description: "I want to report a bug",
                       instruction: "Please provide the bug description in the message field below. <br /> We will contact you as soon as possible.", 
                       id: "bug",
                       modeAvailable: 0 },
                     { description: "I want to change my email address",
                       instruction: "Please provide your new email address in the message field below. <br /> We will contact you as soon as possible.", 
                       id: "change-email",
                       modeAvailable: 2 },
                     { description: "Something else",
                       instruction: "Please explain your inquiry in the message field below. <br /> We will contact you as soon as possible.",
                       id: "other",
                       modeAvailable: 0 } ];

    const onSubmit = (ev, a, b, c) => {
        ev.preventDefault();
        setLoading(true);
        const form = ev.target;
        const topic = form.topic.value;
        const mail = form.mail.value;
        const description = form.description.value;
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lc1IOsdAAAAAEwsT0oTDFpxloWl-kVvsxDSf1Uh', { action: 'submit' }).then(function(token) {
                fetch("/api/inquiry", {
                    method: "POST",
                    body: JSON.stringify({
                        topic, mail, description, token
                    })
                }).then(d => d.json()).then(d => {
                    console.log(d);
                    if (!d.success) {
                        throw new Error(d.error);
                    }
                    setMessage({ msg: "We received your inquiry and will contact you as soon as possible.", 
                                 color: "green"});
                    try {
                        form.reset();
                    } catch (e) {
                        console.log(e);
                    }
                }).catch(er => {
                    setMessage({ msg: "Failed to send your inquiry. <br /> Please send us an email at <a href=\"mailto:office@fipsi.at\">office@fipsi.at</a>.", 
                                 color: "red"});
                }).finally(_ => { setLoading(false); });
            });
        });
    }

    return <>
                { component ?? <AppBar /> }
                <Box>
                    <h1>Contact</h1>

                    <form onSubmit={onSubmit}>
                        <Stack direction="column" sx={{ minWidth: "400px", width: "25vw", margin: component ? "0px 0px 0px 0px" : "auto" }}>
                            <TextField  select
                                        required
                                        disabled={loading}
                                        label="What can we help you with?"
                                        name="topic"
                                        defaultValue={!!searchParams.get("id") ? topics.map(_=>_.id).indexOf(searchParams.get("id")) : undefined}
                                        helperText=""
                                        onChange={(ev, val) => {
                                            console.log(ev, val);
                                            setInstruction(topics[parseInt(val.props.value)].instruction)
                                        }}>
                                {/* TODO: FIX next line */}
                                {   topics.map((option, ind) => { // eslint-disable-line array-callback-return
                                        if (option.modeAvailable === 2 && !defaultEmail) {
                                            return option;
                                        }
                                        return ( <MenuItem key={''+ind} value={''+ind}>
                                                     { option.description }
                                                 </MenuItem>
                                                )
                                    }) }
                            </TextField>

                            <TextField  defaultValue={defaultEmail}
                                        label="Your email"
                                        name="mail"
                                        required
                                        disabled={loading}
                            />  

                            <Typography fontSize="14px"
                                        sx={{ margin: instruction ? "20px 0px 0px" : "0px" }}
                                        dangerouslySetInnerHTML={{ __html: instruction || "" }} />

                            <TextField  multiline
                                        required
                                        disabled={loading}
                                        label={"Please explain your inquiry"}
                                        name="description"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                padding: "0px"
                                            }
                                        }}
                                        minRows={5}
                                        maxRows={10}
                            />

                            <LoadingButton loading={loading}
                                           type="submit" 
                                           className="green" 
                                           sx={{ width: "50%", margin: component ? "20px 0px 0px" : "20px auto 0" }}>
                                Submit
                            </LoadingButton>

                            { message && <Box sx={{ margin: "20px 0px 0px" }}>
                                    <Typography variant="span"
                                                fontSize="14px"
                                                sx={{ color: theme.palette[message.color].main }}
                                                dangerouslySetInnerHTML={{ __html: message.msg }}>
                                    </Typography>
                                </Box>
                            }
                        </Stack>
                    </form>
                </Box>
            </>;
}