import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "components/helper/Loading";


export default function DashboardProfile() {
    const [user, setUser] = useState(null);
    const [updatingUser, setUpdatingUser] = useState(false);

    useEffect(() => {
        fetch("/api/user/current").then(d => d.json()).then(d => {
            if (!d.success)
                throw new Error(d.error);
            setUser(d.result);
        }).catch(er => { alert(er); }).finally(_ => {});
    }, []);

    return (
        <Box>
            <h1>Profile</h1>

            { user === null ?
                <Box sx={{ marginTop: "50px" }}>
                    <Loading>Loading user information...</Loading>
                </Box>
                :
                <Box sx={{ marginTop: "50px" }}>
                    <Stack direction="row" 
                           sx={{ /*justifyContent: "space-between"*/ }}>
                        <Box>
                            <Typography fontWeight="bold"
                                        fontSize="20px">Edit profile</Typography>

                            {/* <Stack direction="row" 
                                spacing={3} 
                                sx={{ marginTop: "20px" }}> */}
                                <Box sx={{ marginTop: "20px" }}>
                                    <TextField label="First name"
                                               id="firstName"
                                               value={user.firstName}
                                               onChange={ev => { setUser({ ...user, firstName: ev.target.value }); }} />
                                </Box>
                                <Box sx={{ marginTop: "20px" }}>
                                    <TextField label="Last name"
                                               id="lastName"
                                               value={user.lastName}
                                               onChange={ev => { setUser({ ...user, lastName: ev.target.value }); }} />
                                </Box>
                            {/* </Stack> */}

                            <Box sx={{ marginTop: "20px" }}>
                                <TextField label="Organization"
                                           id="organization"
                                           value={user.organization}
                                           onChange={ev => { setUser({ ...user, organization: ev.target.value }); }} />
                            </Box>

                            <Box sx={{ marginTop: "20px" }}>
                                <TextField label="Email"
                                           value={user.mail}
                                           disabled
                                           onChange={ev => { setUser({ ...user, mail: ev.target.value }); }} />
                            </Box>
                            <Typography fontSize="14px"
                                        color="secondary"
                                        sx={{ marginTop: "10px" }}>
                                To change your email address, <br />please&nbsp;
                                <Link to="/dashboard/contact?id=change-email">
                                    <Typography fontSize="14px" color="secondary" component="span">contact us.</Typography>
                                </Link> 
                            </Typography>

                            <LoadingButton loading={updatingUser}
                                   type="submit" 
                                   className="blue"
                                   sx={{ margin: "50px auto 0" }}
                                   onClick={() => {
                                        const firstName = document.getElementById("firstName").value;
                                        const lastName = document.getElementById("lastName").value;
                                        const organization = document.getElementById("organization").value;

                                        if (firstName !== "" && lastName !== "") {
                                        } else {
                                            alert("Please fill in all fields.");
                                            return;
                                        }

                                        setUpdatingUser(true);

                                        fetch("/api/user/update", {
                                            method: "POST",
                                            body: JSON.stringify({
                                                firstName,
                                                lastName,
                                                organization,
                                            })
                                        }).then(d => d.json()).then(d => {
                                            if (!d.success)
                                                throw new Error(d.error);
                                        }).catch(er => { alert(er); }).finally(_ => { setUpdatingUser(false); });
                                    }}>
                                Update profile
                            </LoadingButton>
                        </Box>
                        <Box sx={{ marginLeft: "50px", paddingLeft: "50px", borderLeft: "1px solid #ddd" }}>
                            <Typography fontWeight="bold"
                                        fontSize="20px">Security & Password</Typography>

                            <Box sx={{ marginTop: "20px" }}>
                                <TextField
                                    label="Current password"
                                    id="currentPassword"
                                    type="password"
                                    value={user.password}
                                    onChange={ev => {
                                        setUser({ ...user, password: ev.target.value });
                                    }}
                                />
                            </Box>

                            <Box sx={{ marginTop: "20px" }}>
                                <TextField
                                    label="New password"
                                    id="newPassword"
                                    type="password"
                                    value={user.newPassword}
                                    error={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(user.newPassword) === false && user.newPassword?.length > 0}
                                    onChange={ev => {
                                        setUser({ ...user, newPassword: ev.target.value });
                                    }}
                                />
                                <Typography fontSize="12px"
                                            width="250px"
                                            sx={{marginTop: "10px"}}
                                            color="red.main">
                                                {/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(user.newPassword) === false && user.newPassword?.length > 0 ? 
                                                "Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter and one number." : ""}
                                            </Typography>
                            </Box>

                            <Box sx={{ marginTop: "20px" }}>
                                <TextField
                                    label="Confirm new password"
                                    id="confirmNewPassword"
                                    type="password"
                                    value={user.confirmNewPassword}
                                    error={user.newPassword !== user.confirmNewPassword}
                                    helperText={user.newPassword !== user.confirmNewPassword ? "Passwords do not match" : ""}
                                    onChange={ev => {
                                        setUser({ ...user, confirmNewPassword: ev.target.value });
                                    }}
                                />
                            </Box>

                            <LoadingButton loading={updatingUser}
                                   type="submit" 
                                   className="blue"
                                   sx={{ margin: "50px auto 0" }}
                                   onClick={() => {
                                        const currentPassword = document.getElementById("currentPassword").value;
                                        const newPassword = document.getElementById("newPassword").value;
                                        const confirmNewPassword = document.getElementById("confirmNewPassword").value;

                                        if (newPassword !== confirmNewPassword) {
                                            alert("New password and confirm new password do not match.");
                                            return;
                                        }

                                        setUpdatingUser(true);

                                        fetch("/api/user/update/password", {
                                            method: "POST",
                                            body: JSON.stringify({
                                                currentPassword,
                                                newPassword,
                                            })
                                        }).then(d => d.json()).then(d => {
                                            if (!d.success)
                                                throw new Error(d.error);
                                        }).catch(er => { alert(er); }).finally(_ => { setUpdatingUser(false); });
                                    }}>
                                Update password
                            </LoadingButton>
                        </Box>
                        <Box sx={{ marginLeft: "50px", paddingLeft: "50px", borderLeft: "1px solid #ddd" }}>
                            <Typography fontWeight="bold"
                                        fontSize="20px">Danger Zone</Typography>

                            {/* a button to delete the account */}
                            <LoadingButton loading={updatingUser}
                                           className="red"
                                           color="red"
                                           sx={{ marginTop: "40px", borderColor: "red.main" }}
                                           onClick={() => {
                                                if (!window.confirm("Are you sure you want to delete your account?"))
                                                    return;

                                                setUpdatingUser(true);

                                                fetch("/api/user/delete", {
                                                    method: "POST",
                                                    body: JSON.stringify({})
                                                }).then(d => d.json()).then(d => {
                                                    if (!d.success)
                                                        throw new Error(d.error);
                                                }).catch(er => { alert(er); }).finally(_ => { setUpdatingUser(false); });
                                            }}>
                                Delete account
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Box>
            }
        </Box>
    );
} 