import { Box, Icon, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SidebarElement (props) {
    const theme = useTheme();
    const nav = useNavigate();
    
    return (
        <Box className="sidebar-element" sx={{...{
                padding: "15px 0px 15px 40px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                "&:not(.active):hover": {
                    // backgroundColor: "#e2edfb",
                    backgroundColor: "#ebebeb",
                    "& *": {
                        color: "#0f5ab1",
                    }
                },
                "&.active": {
                    backgroundColor: "#e2edfb",
                    "& *": {
                        color: "#0f5ab1",
                    }
                },
            }, ...props.style, ...props.sx
        }} onClick={() => { return props.to ? nav(props.to) : null } }
        {...props}
        >

            <Icon baseClassName="material-icons-round" sx={{
                ...{
                    marginRight: "10px",
                    fontSize: "19px",
                    color: theme.palette.primary.main
                }, ...props.sx, ...props.style
            }}>{props.icon}</Icon>

            <Typography variant="span"
                        component="span" 
                        fontSize="15px"
                        fontWeight="500"
                        sx={{
                            color: theme.palette.primary.main,
                        }}
            >
                {props.children}
            </Typography>
        </Box>
    )
}