import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";

import TextVisual from "./visuals/TextVisual";
import AudioVisual from "./visuals/AudioVisual";
import ImageVisual from "./visuals/ImageVisual";


export default function Graphic () {
    const [chosenDemo, setChosenDemo] = useState("image");

    return (
        <Box>
            <ToggleButtonGroup color="green" value="image" exclusive>
                <TB value="image" active={chosenDemo === "image"} ev={() => { setChosenDemo("image") }}>
                    Image analysis
                </TB>
                <TB value="audio" active={chosenDemo === "audio"} ev={() => { setChosenDemo("audio") }}>
                    Audio profiling
                </TB>
                <TB value="text" active={chosenDemo === "text"} ev={() => { setChosenDemo("text") }}>
                    Text manipulation
                </TB>
            </ToggleButtonGroup>

            <Box position="relative">
                <Box position="absolute" sx={{ transition: "0.2s", opacity: (chosenDemo === "text"  ? 1 : 0) }}>
                    <TextVisual /> 
                </Box>
                <Box position="absolute" sx={{ transition: "0.2s", opacity: (chosenDemo === "image" ? 1 : 0) }}>
                    <ImageVisual />
                </Box>
                <Box position="absolute" sx={{ transition: "0.2s", opacity: (chosenDemo === "audio" ? 1 : 0) }}>
                    <AudioVisual />
                </Box>
            </Box>
        </Box>
    );
}

const TB = ({ value, children, active, ev }) => {
    return (
        <ToggleButton value={value} sx={{ border: "none !important", position: "relative",
                                          "&::before": { content: "''", height: "3px", width: (active ? "85%" : "20px"), margin: "auto", 
                                                         left: "0px", right: "0px", bottom: "3px", position: "absolute",
                                                         backgroundColor: "#fff", borderRadius: "99px",
                                                         transition: ".2s" },
                                          "&:hover::before": { width: (active ? "85%" : "45%") } }}
                        onChange={() => { ev() }}>
            <Typography color="#fff" sx={{ textTransform: "none" }}>
                { children }
            </Typography>
        </ToggleButton>
    );
}