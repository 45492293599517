import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from "react";


export default function FAQs () {
    return (
        <Box sx={{ width: "50vw", margin: "auto", "& b": { fontWeight: "bold" } }}>
            <FAQ question="How does Friday help me?" 
                 answer="Friday helps you building applications that are powered by artificial intelligence. <br /><br />Hosting your own infrastructure for face recognition or audio classification is expensive. In most cases it's more cost-effective to offload that requests to our blazing-fast API." />
            <FAQ question="Do you offer a free plan?" 
                 answer="Yes, we offer a free plan for trialing and testing. No credit card is required to use it. <br /><br />When upgrading to a paid plan, you get a free 30-days trial" />
            <FAQ question="What can I do with this API?" 
                 answer="Our API provides a wide range of problem solving AI models and endpoints. Here's a list:
                            <ul>
                                <li><b>Audio classification</b> helps you identify speakers in a voice recording and transcribe to spoken words to text (TTS)</li>
                                <li><b>Image processing</b> allows you to identify objects in images and extract text from an image</li>
                                <li><b>Text analysis</b> is used to solve a variety of tasks from question answering, translating, correcting grammar mistakes and creating sophisticated chatbots </li>
                            </ul>" last />
        </Box>
    );
}


const FAQ = ({ question, answer, last }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Accordion sx={{
                boxShadow: "none",
                // borderRadius: "4px",
                color: theme.palette.white.main,
                backgroundColor: "transparent",
                margin: "0px 0px !important",
                "&:before": {
                    display: "none !important",
                },
            }}>
                <AccordionSummary expandIcon={open ? <RemoveIcon color="white" /> : <AddIcon color="white" />} 
                                  onClick={() => { setOpen(!open) }}
                                  sx={{ padding: "10px 20px !important", flexDirection: "row-reverse" }} >
                    <Typography fontSize="18px" margin="0px 0px 0px 20px">{question}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px 20px 40px !important" }} >
                    <Typography fontSize="16px" dangerouslySetInnerHTML={{
                        __html: answer
                    }} />
                </AccordionDetails>
            </Accordion>

            { !last && <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.7)" }} /> }
        </>
    );
}