import { Stack, Typography } from "@mui/material";
import GitHubLogo from "./img/GitHub-Mark-Light-32px.png";


export default function SignInGithub() {
    return (
        <a href="https://github.com/login/oauth/authorize?scope=user:email&client_id=86c7d28233ec83f3eab3" style={{ textDecoration: "none" }}>
            <Stack direction="row" sx={{ backgroundColor: "#24292d", 
                                        margin: "auto !important",
                                        padding: "15px 20px", 
                                        cursor: "pointer", 
                                        alignItems: "center", 
                                        width: "220px",
                                        justifyContent: "space-between" }}>
                <img height={25} src={GitHubLogo} alt="GitHub logo" />
                <Typography color="#fff">Sign in with GitHub</Typography>
            </Stack>
        </a>
    );
}
