import { Box } from "@mui/material";
import { useEffect } from "react";
import AppBar from "components/helper/AppBar";


export default function Imprint () {
    useEffect(() => {
        document.title = "Friday - Imprint";
    }, []);

    return <>
        <AppBar />
        <Box sx={{
            padding: "0 50px 400px",
            "& h1, & h2": {
                textAlign: "left",
            },
            "& h1": {
                paddingTop: "125px !important",
                fontSize: "25px",
            },
            "& h2": {
                fontSize: "20px",
            },
            "& p, & li": {
                fontSize: "14px",
            }
        }}>
            <h1>Impressum</h1>

            Philipp Scheer e.U.

            <p>
                Philipp Scheer <br />   
                Sturzgasse 32/25 <br />   
                1150 Wien<br />   
                E-Mail: office@fipsi.at<br />   
                Tel: +4367761244487<br />   
            </p>

            <br />
            <h2>Google Analytics</h2>

            <p>
                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google''). 
                Google Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert werden 
                und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten 
                Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) wird an einen Server 
                von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, 
                um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber 
                zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene 
                Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, 
                sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. 
                Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. 
                Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software 
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche 
                Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären 
                Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art 
                und Weise und zu dem zuvor benannten Zweck einverstanden.

                Sie können der Erhebung und Speicherung Ihrer Daten jederzeit <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="blank">widersprechen</a>.
            </p>

            <p>
                <a href="http://www.agb.de">Gratis Impressum</a> von agb.de
            </p>
        </Box>
    </>;
}