import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import App from './components/App';
import { createTheme, ThemeProvider } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


let bxs = "6px 6px 0 0";
let boxShadow = `${bxs} #000`;
let border = "2px solid #000";


const mainTheme = createTheme({
    typography: {
        fontFamily: "Inter"
    },
    components: {
        MuiTypography: { styleOverrides: { root: {
            fontFamily: "Inter",
            fontSize: "15px",
        } } },

        // general input styles
        MuiOutlinedInput: { styleOverrides: { root: {
            "& fieldset": {
                borderColor: "transparent",
            },
            "&:hover fieldset": {
                borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
                borderColor: "transparent",
                borderWidth: "1px"
            },
            "&.visibleLegend": {
                "& legend": {
                    visibility: "visible",
                    "& span": {
                        fontSize: "1rem",
                        transform: "translate(0px, 80%)",
                    }
                }
            },
            "& + .MuiFormHelperText-root": {
                fontSize: "1rem",
                transform: "translate(13px, -38px)",
                margin: "0px",
                pointerEvents: "none",
                opacity: "1",
                transition: ".1s",
            },
            ".MuiFormLabel-filled + & + .MuiFormHelperText-root": {
                opacity: "0",
            }
        }, input: {
            border: "1px solid #000",
            borderRadius: "0px !important",
            padding: "12px",
            ":hover, :focus": {
                borderWidth: "2px",
                padding: "11px"
            },
            boxShadow: "0 0 0 1000px white inset !important",
        }, notchedOutline: {
            border: "none !important",
        } } },
        MuiInputLabel: { styleOverrides: { root: {
            background: "#fff",
            padding: "4px 8px",
            fontSize: ".75rem",
            border: "1px solid #000",
            transform: "translate(0px, -26px)",
            transition: "0s",
            "&.Mui-focused": {
                backgroundColor: "#000",
                color: "#fff",
            }
        } } },
        MuiFormControl: { styleOverrides: { root: {
            margin: "27px 0px 15px !important",
        } } },
        MuiInputBase: { styleOverrides: { root: {
            borderRadius: "0px !important",
            fontSize: "1rem",
        }, input: {
            padding: "12px",
        } } },
        MuiInput: { styleOverrides: { root: {

        } } },





        MuiMenu: { styleOverrides: { paper: {
            boxShadow: "none !important",
            border: "1px solid #000",
            borderRadius: "0px !important",
            transform: "translate(-2px, -1px) !important",
        } } },
        MuiList: { styleOverrides: { root: {
            padding: "0px !important",
        } } },
        MuiMenuItem: { styleOverrides: { root: {
            padding: "10px 20px",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#fff !important",
            color: "#000",
            "&:last-child": {
                borderBottom: "none",
            },
            "&:hover": {
                backgroundColor: "#000 !important",
                color: "#fff",
            }
        } } },



        MuiButton: { styleOverrides: { root: {
            padding: "10px 20px",
            transition: ".2s",
            textTransform: "none",
            color: "#363636",
        },
        text: {
            borderRadius: "0px",
            border: "none",
            backgroundColor: "transparent",
            "&, & *": {
                transition: ".2s",
            },
            "&:after": {
                content: "'arrow_forward'",
                transition: ".1s",
                fontSize: "20px",
                fontFamily: "Material Icons Round",
                WebkitFontFeatureSettings: 'liga',
                transform: "translateX(10px)",
            },
            "&:hover::after": {
                transform: "translateX(15px)",
            },
            "&.green:hover, &.green:hover *": { color: "#0abd75 !important" },
            "&.blue:hover, &.blue:hover *": { color: "#4287f5 !important" },
            "&.orange:hover, &.orange:hover *": { color: "#FFA23F !important" },
            "&.red:hover, &.red:hover *": { color: "#ff3f3f !important" },
            "&:hover": {
                boxShadow: "none !important",
                backgroundColor: "transparent !important",
            },
        },
        contained: {
            // boxShadow: "none",
            backgroundColor: "#fff",
            border: "2px solid #000",
            borderRadius: "0px",
            "&:hover": {
                boxShadow: boxShadow,
                backgroundColor: "#fff",
            },
            "&.red:hover": { boxShadow: `${bxs} #ff3f3f`, },
            "&.green:hover": { boxShadow: `${bxs} #0abd75`, },
            "&.blue:hover": { boxShadow: `${bxs} #4287f5`, },
        }
        } },
        MuiDialog: { styleOverrides: { paper: {
            boxShadow: "none",
            borderRadius: "0px",
            padding: "10px 5px 5px",
        } } },
        MuiDialogTitle: { styleOverrides: { root: {
            paddingBottom: "2px",
        } } },
        MuiDialogContentText: { styleOverrides: { root: {
            fontSize: "14px",
        } } },


        // TABLE STYLES
        MuiTableBody: { styleOverrides: { root: {
            // border: border,
            // borderStyle: "solid none none none",
        } } },
        MuiTableContainer: { styleOverrides: { root: {
            backgroundColor: "#fff",
            // border: border,
        } } },
        MuiTableRow: { styleOverrides: { head: {
            borderTop: "none !important",
        }, root: {
            "&.hover:hover > td": {
                color: "#fff",
                backgroundColor: "#000",
                cursor: "pointer",
            },
            "&:nth-child(1)": {
                border: border,
                borderStyle: "solid none none none",
            }
        } } },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                },
                head: {
                    borderTop: "none",
                    fontWeight: "bold",
                }
            }
        },


        MuiLinearProgress: { styleOverrides: { bar: {
            animationDuration: "0.75s !important"
        } } },
        MuiToggleButton: { styleOverrides: { root: {
            border: "2px solid #000",
            borderRadius: "0px",
            transition: ".2s",
            marginRight: "15px !important",
            borderLeft: "2px solid #000 !important",
            "&.Mui-selected": {
                boxShadow: "6px 6px 0 0",
            },
        } } },
    },
    palette: {
        primary: {
            light: "#bfbfbf",
            main: "#363636"
        },
        secondary: {
            main: "#aaaaaa"
        },
        error: {
            main: "#ff3f3f"
        },
        red: {
            main: "#ff3f3f"
        },
        success: {
            main: "#0abd75"
        },
        green: {
            main: "#0abd75"
        },
        warning: {
            main: "#FFA23F"
        },
        orange: {
            main: "#FFA23F"
        },
        info: {
            main: "#4287f5"
        },
        blue: {
            main: "#4287f5"
        },
        white: {
            main: "#ffffff",
        },
        yellow: {
            main: "#ffe01b"
        }
    },
});


const stripe = loadStripe("pk_test_51KFKi8Kivru0dBrlQR9CYPUjNWBXIsm6PMd2ryXHEF7yDOSKRt21HqbvoX0BeGmdhSQxqIRJnNSUZavBGlR2LJPg00vhsnw6tl");


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={mainTheme}>
            <Elements stripe={stripe}>
                <App />
            </Elements>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

