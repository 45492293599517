import { Stack, Typography } from "@mui/material";

import alphaCepheiLogo from './graphics/alphacephei-logo.png';


export default function ModelsPage () {
    return (
        <Stack direction="row" margin="0px" backgroundColor="#f3f3f3" padding="30px 400px" justifyContent="space-between" alignItems="center">
            <Typography>Models from:</Typography>
            {   [  { src:"/img/logo/meta.svg", 
                     size: 15,
                     title: "Meta (Facebook)",
                }, { src:"/img/logo/amazon.svg", 
                     size: 21,
                     title: "Amazon (AWS)",
                     transform: "translate(0px, 3px)"
                }, { src:"/img/logo/google.svg", 
                     size: 25,
                     title: "Google AI",
                }, { src:"/img/logo/microsoft.svg", 
                     size: 24,
                     title: "Microsoft",
                }, { src: alphaCepheiLogo,
                     size: 24,
                     title: "AlphaCephei",
                } ].map(el => (
                <img src={el.src} height={el.size} style={{ transform: el.transform }} title={el.title} alt="" />
            ))}
        </Stack>
    );
}