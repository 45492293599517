import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, FormControl, Select, DialogTitle, TextField, Typography, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const rockeet = window.rockeet;


export default function AssistantCreate() {
    const [open, setOpen] = useState(false);
    const [newAssistantErrors, setNewAssistantErrors] = useState({ "name": false, "language": false });
    const [newAssistantLanguage, setNewAssistantLanguage] = useState("en");
    const [newAssistantLoading, setNewAssistantLoading] = useState(false);
    const nav = useNavigate();


    const handleClickOpen = () => {
        setOpen(true);
        setTimeout(() => {
            document.getElementById("new-assistant-name").focus();
        });
    };

    const handleClose = (create) => {
        try {
            if (create) {
                const name = document.getElementById("new-assistant-name").value;
                // /[A-Za-z0-9][A-Za-z0-9-]+/
                const language = newAssistantLanguage;
                const errors = { "name": false, "language": false };
                
                if (name.trim() === "")                                  { errors.name = true; }
                if (! ["en", "de", "es", "fr", "it"].includes(language)) { errors.language = true; }
                if (errors.language || errors.name)                      { setNewAssistantErrors(errors); return; }
    
                setNewAssistantLoading(true);
    
                rockeet.Assistant.new(name, language).then(res => {
                    if (res.success)
                        nav(`/console/a/${res.result}`);
                    else
                        throw new Error(res.result);
                }).catch(er => {
                    alert(er);
                }).finally(() => {
                    setNewAssistantLoading(false);
                    setOpen(false);
                });
            } else {
                setOpen(false);
            }
        } catch(er) {
            console.error(er);
        }
    };

    return (
        <>
            {/* LOADING BACKDROP - when a new assistant is created */}
            { <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={newAssistantLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }


            {/* CREATE BUTTON */}
            <Button onClick={handleClickOpen} variant="contained">
                Create a new assistant
            </Button>


            {/* NEW ASSISTANT DIALOG */}
            <Dialog
                open={open}
                onClose={()=>{handleClose(false);}}
                aria-labelledby="Create new assistant"
                aria-describedby="Create a new assistant with a name, language and metadata"
                >
                <DialogTitle id="create-assistant-title">
                    <Typography fontSize="1.15rem" fontWeight="500">
                        Create a new assistant
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ marginTop: "20px" }}>
                    <Stack direction="column">
                        <TextField label="Assistant name" defaultValue="Jarvis" autoFocus error={newAssistantErrors["name"]} id="new-assistant-name" />
                        <FormControl>
                            <InputLabel id="new-assistant-language-label">Language</InputLabel>
                            <Select
                                MenuProps={{ disableScrollLock: true }}
                                labelId="new-assistant-language-label"
                                id="new-assistant-language"
                                value={newAssistantLanguage}
                                label="Language"
                                onChange={event => {setNewAssistantLanguage(event.target.value)}}
                            >
                                <MenuItem value={"en"}><img src="https://flagcdn.com/us.svg" alt="English" height="13" /> <Typography component="span" sx={{marginLeft: "10px"}}> English </Typography></MenuItem>
                                <MenuItem value={"de"}><img src="https://flagcdn.com/de.svg" alt="German"  height="13" /> <Typography component="span" sx={{marginLeft: "10px"}}> German  </Typography></MenuItem>
                                <MenuItem value={"es"}><img src="https://flagcdn.com/es.svg" alt="Spanish" height="13" /> <Typography component="span" sx={{marginLeft: "10px"}}> Spanish </Typography></MenuItem>
                                <MenuItem value={"fr"}><img src="https://flagcdn.com/fr.svg" alt="French"  height="13" /> <Typography component="span" sx={{marginLeft: "10px"}}> French  </Typography></MenuItem>
                                <MenuItem value={"it"}><img src="https://flagcdn.com/it.svg" alt="Italian" height="13" /> <Typography component="span" sx={{marginLeft: "10px"}}> Italian </Typography></MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose(false); }}>Cancel</Button>
                    <Button onClick={() => { console.log("CLICK", handleClose); handleClose(true); }}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}