import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import Loading from "components/helper/Loading";


export default function ApplicationUsage() {
    const [chartRequestData, setChartRequestData] = useState([]);
    const [chartSuccessData, setChartSuccessData] = useState([]);
    const [tokenInformation, setTokenInformation] = useState({});
    const [dataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        setDataLoading(true);
        Promise.all([
            fetch("/api/token/usage"),
            fetch("/api/user/tokens"),
        ]).then(res => {
            Promise.all([
                res[0].json(),
                res[1].json(),
            ]).then(results => {
                let d = results[0];
                const generalData = {};
                const successData = {};
                for (const token in d.result) {
                    if (Object.hasOwnProperty.call(d.result, token)) {
                        const timestamps = d.result[token];
                        for (const timestamp in timestamps) {
                            if (Object.hasOwnProperty.call(timestamps, timestamp)) {
                                const data = timestamps[timestamp];
                                if (!generalData[token]) {
                                    generalData[token] = [];
                                    successData[token] = [];
                                }

                                successData[token].push({
                                    "timestamp": timestamp*1000,
                                    "token": token,
                                    "success": data.success.true,
                                    "failure": data.success.false,
                                });

                                generalData[token].push({
                                    "timestamp": timestamp*1000,
                                    "token": token,
                                    "requests": data.count,
                                });
                            }
                        }
                    }
                }

                setChartRequestData(generalData);
                setChartSuccessData(successData);
                
                d = results[1];
                if (!d.success)
                throw new Error(d.error);
                
                const tkInfo = {};
                d.result.forEach(el => {
                    tkInfo[el.id] = el;
                });
                setTokenInformation(tkInfo);

                setDataLoading(false);
            });
        })
    }, []);

    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }
      
    const datapointsStart = (new Date()).setMinutes(0, 0, 0).valueOf() - 60 * 24 * 60_000; // subtract 24 hours
    const datapoints = [];
    for (let i = 0; i < 25; i++) {
        datapoints.push(datapointsStart.valueOf() + i * 60 * 60_000);
    }
    const colors = [
        "#4287f5", // theme blue
        "#ffa23f", // theme orange
        "#0abd75", // theme green
        "#ff8c3f",
        "#423fff",
        "#ff3f8f",
    ];

    let cachedToolTip = <></>;
    let axisFontSize = "12px";
    // let legendFontSize = "14px";
    let toolTipFontSize = "14px";


    return (
        <Box sx={{
            display: "grid",
            gridTemplateRows: "200px 200px",
            gridTemplateColumns: "1fr 1fr",
            width: "calc(100vw - 250px - 250px)",
            maxWidth: "800px",
            "& .chart": {
                border: "1px solid rgba(0,0,0,0.12)",
                borderStyle: "none none solid none",
            },
            "& .chart:nth-child(odd)": {
                borderStyle: "none solid solid none",
            },
            "& .chart.noBorder": {
                borderBottomStyle: "none",
            },
            "& .chart .chartContainer": {
                padding: "0px 0px 50px 0",
                height: "100%",
                width: "100%",
                transform: "translate(-20px, 0px)",
            },
            "& .MuiTypography-root": {
                padding: "20px 30px 10px",
                fontWeight: "bold",
            },
        }}>
            { dataLoading ? 
                <Loading>Loading application usage...</Loading>
                :
                <>
                    <Box className="chart noBorder">
                        <Typography>Requests per application</Typography>
                        <Box className="chartContainer">
                            <ResponsiveContainer>
                                <LineChart 
                                    margin={{ top: 5, right: 20, left: -10, bottom: 5 }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis
                                        dataKey="timestamp"
                                        scale="time"
                                        type="number"
                                        fontSize={axisFontSize}
                                        domain={[datapoints[0], datapoints[datapoints.length - 1]]}
                                        interval={1}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={false}
                                        tickFormatter={(val, ind) => { return moment(val).format("HH:mm") } }
                                        ticks={datapoints} 
                                        />
                                    <YAxis 
                                        axisLine={false}
                                        tickLine={false}
                                        tick={false}
                                        fontSize={axisFontSize}
                                        tickFormatter={(val, ind) => { return nFormatter(val, 0) }}
                                        />
                                    {
                                        Object.keys(chartRequestData).map((key, index) => {
                                            return <Line
                                                type="monotone"
                                                data={chartRequestData[key]}
                                                dataKey="requests"
                                                stroke={colors[index]}
                                                strokeWidth={3}
                                                dot={false}
                                                name={`${tokenInformation[key]?.name}`} // change the legend token names here
                                            />
                                        })
                                    }
                                    <Tooltip
                                        isAnimationActive={false}
                                        cursor={true}
                                        content={({ active, payload, label }) => {
                                            if (active && payload) {
                                                cachedToolTip = <Box sx={{
                                                    backgroundColor: "rgba(255,255,255,0.9)",
                                                    boxShadow: `0px 0px 5px 2px rgba(0,0,0,0.15)`,
                                                    padding: "5px 10px",
                                                    borderRadius: "3px",
                                                }}>
                                                    {   payload.map(el => {
                                                            return <> <span style={{ fontSize: toolTipFontSize, color: el.stroke }}> { el.name }:</span> <span style={{fontSize: toolTipFontSize}}> { el.value } requests </span> <br /> </>
                                                        })  }
                                                </Box>;
                                                return cachedToolTip;
                                            }
                                            return null;
                                        }}
                                        />
                                    {/* <Legend 
                                        layout="vetical"
                                        verticalAlign="top"
                                        align="center"
                                        wrapperStyle={{ 
                                            top: 20, 
                                            left: 70, 
                                            backgroundColor: "rgba(255,255,255,0.7)", 
                                            boxShadow: `0px 0px 5px 2px rgba(0,0,0,0.15)`, 
                                            padding: "5px 7px",
                                            borderRadius: "3px",
                                            fontSize: legendFontSize,
                                        }}
                                        /> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                    <Box className="chart noBorder">
                        <Typography>Successful requests</Typography>
                        <Box className="chartContainer">
                            <ResponsiveContainer>
                                <LineChart 
                                    margin={{ top: 5, right: 20, left: -10, bottom: 5 }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis
                                        fontSize={axisFontSize}
                                        dataKey="timestamp"
                                        scale="time"
                                        type="number"
                                        axisLine={false}
                                        tickLine={false}
                                        tick={false}
                                        domain={[datapoints[0], datapoints[datapoints.length - 1]]}
                                        interval={1}
                                        tickFormatter={(val, ind) => { return moment(val).format("HH:mm") } }
                                        ticks={datapoints} 
                                        />
                                    <YAxis
                                        fontSize={axisFontSize}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={false}
                                        tickFormatter={(val, ind) => { return nFormatter(val, 0) }}
                                        />
                                    {
                                        Object.keys(chartSuccessData).map((key, index) => {
                                            return <>
                                            <Line
                                                type="monotone"
                                                data={chartSuccessData[key]}
                                                dataKey="success"
                                                stroke={colors[index]}
                                                strokeWidth={3}
                                                dot={false}
                                                name={`${tokenInformation[key]?.name} - Successful requests`}
                                            />
                                            <Line
                                                type="monotone"
                                                data={chartSuccessData[key]}
                                                dataKey="failure"
                                                stroke={colors[index]}
                                                strokeWidth={3}
                                                dot={false}
                                                name={`${tokenInformation[key]?.name} - Failed requests`}
                                            />
                                            </>
                                        })
                                    }
                                    <Tooltip
                                        isAnimationActive={false}
                                        cursor={true}
                                        content={({ active, payload, label }) => {
                                            if (active && payload) {
                                                const consumedTokens = {};
                                                const newPayload = payload.filter(val => {
                                                    let alreadyConsumed = !!consumedTokens[val.payload.token];
                                                    if (!alreadyConsumed) {
                                                        consumedTokens[val.payload.token] = true;
                                                        return true;
                                                    }
                                                    return false;
                                                });

                                                if (newPayload.length !== payload.length/2)
                                                    return cachedToolTip;

                                                cachedToolTip = <Box sx={{
                                                    backgroundColor: "rgba(255,255,255,0.9)",
                                                    boxShadow: `0px 0px 5px 2px rgba(0,0,0,0.15)`,
                                                    padding: "5px 10px",
                                                    borderRadius: "3px",
                                                }}>
                                                    {
                                                        newPayload.map(el => {
                                                            return <> 
                                                                    <span style={{ fontSize: toolTipFontSize, color: el.stroke }}> { tokenInformation[el.payload.token]?.name }: </span>
                                                                    <span style={{ fontSize: toolTipFontSize, color: "#29991d" }}>{ el.payload.success }</span>/
                                                                    <span style={{ fontSize: toolTipFontSize, color: "#ff3f3f" }}>{ el.payload.failure } </span>
                                                                    { el.payload.failure+el.payload.success === 0 ? "" : "(" + (100 * el.payload.success/(el.payload.failure+el.payload.success)).toFixed(1) + "%)" }
                                                                    <br/>
                                                                </>;
                                                        })
                                                    }
                                                </Box>;
                                                return cachedToolTip;
                                            }
                                            return null;
                                        }}
                                        />
                                    {/* <Legend 
                                        layout="vetical"
                                        verticalAlign="top"
                                        align="center"
                                        wrapperStyle={{ 
                                            top: 20, 
                                            left: 70, 
                                            backgroundColor: "rgba(255,255,255,0.7)", 
                                            boxShadow: `0px 0px 5px 2px rgba(0,0,0,0.15)`, 
                                            padding: "5px 7px",
                                            borderRadius: "3px",
                                            fontSize: legendFontSize
                                        }}
                                        payload={[
                                            {
                                                id: "success",
                                                type: "line",
                                                value: "Successful requests",
                                                color: "#29991d",
                                            },
                                            {
                                                id: "error",
                                                type: "line",
                                                value: "Failed requests",
                                                color: "#ff3f3f",
                                            }
                                        ]}
                                        /> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                    
                </>
            }
        </Box>
    );
}