import { Box, Toolbar, Typography, useTheme } from "@mui/material";

import { Link } from "react-router-dom";
import { useState } from "react";

import API_DOC from "./DocsStyle.jsx";
import AppBar from "components/helper/AppBar";


export default function ApiDoc () {
    const theme = useTheme();
    const [ready, setReady] = useState(false);

    return (
        <Box>
            <AppBar position="static" sx={{ fontFamily: theme.typography.fontSecondary }}>
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="span" sx={{ lineHeight: "unset" }}>
                        <Link to="/" style={{ textDecoration: "none", color: theme.palette.text.primary, fontFamily: theme.typography.fontPrimary, fontSize: "22px" }}>Friday</Link>
                    </Typography>
                </Toolbar>
            </AppBar>

            <Typography component="h1"
                        fontSize="30px"
                        textAlign="left"
                        sx={{ marginLeft: "350px", display: ready ? "block" : "none" }}>API Documentation</Typography>

            <Box sx={{marginTop: "150px"}} />

            <API_DOC onReady={() => { setReady(true); }} />
        </Box>
    );
}