import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../helper/Loading";
import AssistantCreate from "./AssistantCreate";


const rockeet = window.rockeet;


const flagMappings = {
    "en": "us",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "it": "it"
};
const languageMappings = {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "fr": "French",
    "it": "Italian"
};


export default function AssistantList({ token }) {
    const [assistants, setAssistants] = useState(null);
    const nav = useNavigate();


    useEffect(() => {
        rockeet.setToken(token);
        rockeet.Assistant.list().then(res => {
            setAssistants(res);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {/* LOADING ASSISTANTS */}
            { assistants === null &&
                <Loading sx={{ marginTop: "50px" }}>
                    Loading assistants...
                </Loading>
            }


            {/* NO ASSISTANTS */}
            { assistants && assistants.length === 0 &&
                <>
                    <Typography>
                        No assistants yet.
                    </Typography>

                    <AssistantCreate />
                </>
            }


            {/* LIST EXISTING ASSISTANTS */}
            { assistants && assistants.length > 0 &&
                <>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...assistants].map((assistant) => (
                                <TableRow
                                    onClick={() => nav(`/console/a/${assistant.assistantId}`)}
                                    className="hover"
                                    key={assistant.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{assistant.name}</TableCell>
                                    <TableCell>
                                        <img src={`https://flagcdn.com/${flagMappings[assistant.language]}.svg`} alt={languageMappings[assistant.language]} height="13" />
                                        <Typography 
                                            component="span" 
                                            sx={{ marginLeft: "10px" }}
                                        > 
                                            { languageMappings[assistant.language] } 
                                        </Typography>
                                    </TableCell>
                                    <TableCell><Typography copyable fontFamily="JBMono" component="span" fontSize="0.75rem">{assistant.assistantId}</Typography></TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <AssistantCreate />
                </>
            }
        </>
    );
}