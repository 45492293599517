import { Backdrop, Button, CircularProgress, Dialog, DialogTitle, DialogContent, Stack, TextField, DialogActions, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';


const rockeet = window.rockeet;


export default function NewIntent ({ assistantId, token }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [intentNameError, setIntentNameError] = useState(false);
    const [newIntentLoading, setNewIntentLoading] = useState(false);
    const nav = useNavigate();

    rockeet.setToken(token);
    const assistant = new rockeet.Assistant(assistantId);

    const handleClickOpen = () => {
        setModalOpen(true);
        setTimeout(() => {
            document.getElementById("new-intent-name").focus();
        });
    };
    
    const handleClose = (create) => {
        if (create) {
            let intentName = document.getElementById("new-intent-name").value;
            if (intentName.trim() === "") {
                setIntentNameError("Please enter a name for this intent");
                return;
            }
            setNewIntentLoading(true);
            assistant.Intent.add(intentName).then(d => {
                console.log("ARGS", d, arguments);
                nav(`/console/a/${assistantId}/i/${d.result}`);
                setModalOpen(false);
            }).catch(er => {
                console.log("ARGSER", er, arguments);
                setIntentNameError("NOT TOK");
            }).finally(() => {
                setNewIntentLoading(false);
            });
        } else {
            setModalOpen(false);
        }
    }

    return (
        <>
            <Button onClick={() => handleClickOpen()}>New Intent</Button>


            {/* NEW INTENT LOADING */}
            <Backdrop open={newIntentLoading}>
                <CircularProgress />
            </Backdrop>


            {/* NEW INTENT DIALOG */}
            <Dialog
                open={modalOpen}
                onClose={()=>{handleClose(false);}}
                aria-labelledby="Create new intent"
                aria-describedby="Create a new intent with a name, language and metadata"
                >
                <DialogTitle id="create-assistant-title">
                    <Typography fontSize="1.15rem" fontWeight="500">
                        Create a new intent
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ marginTop: "20px" }}>
                    <Stack direction="column" sx={{zIndex: 9999}}>
                        <TextField label="Intent name" helperText="getCurrentWeather" autoFocus error={!!intentNameError} id="new-intent-name" />
                        { intentNameError && <Typography fontSize="0.75rem" color="error">{intentNameError}</Typography> }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose(false); }}>Cancel</Button>
                    <Button onClick={() => { handleClose(true); }}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}