import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import Graphic from './Graphic';


export default function AboveFold () {
    const theme = useTheme();

    return (
        <Box sx={{ padding: "0px", position: "relative", overflow: "hidden" }} height="80vh" id="home">
            <Box sx={{ backgroundColor: "green.main", position: "absolute", left: 0, top: 0, width: "100vw", height: "80vh", zIndex: -1, overflow: "hidden" }} />

            {/* element above the fold */}
            <Stack direction="row" justifyContent="space-between" height="80vh">
                <Stack direction="column" spacing={3} width="40%" padding="250px 0px 0px 250px">
                    <Typography component="h1"
                                fontSize="40px"
                                fontWeight="bold"
                                color="white.main"
                                sx={{ gridArea: "title" }}>
                        Bring AI to all your applications
                    </Typography>

                    <Typography fontSize="20px"
                                color="white.main"
                                margin="30px 0 !important"
                                sx={{ gridArea: "subtitle" }}>
                        Friday's API helps businesses and developers build AI-powered applications.
                    </Typography>

                    <Box sx={{ gridArea: "cta" }} margin="0px !important">
                        <Button className="green" variant="contained">
                            <Link to="/signup"
                                style={{ textDecoration: "none",
                                        fontSize: "18px",
                                        color: theme.palette.primary.main
                                         }}>
                                Start building for free
                            </Link>
                        </Button>
                        <Typography color="rgba(255,255,255,0.75)" fontSize="13px" margin="20px 0 0">
                            No credit card required. Free forever. <br />
                            30-day free trial after upgrading. 
                        </Typography>
                    </Box>
                </Stack>

                <Box padding="100px" width="50%">
                    <Graphic />
                </Box>
            </Stack>
        </Box>
    );
}