import { Box } from "@mui/material";
import "./style/ScrollDown.scss";


export default function ScrollDown ({ width, height, padding, border, opacity, innerHeight, sxInner, sxOuter }) {
    return (
        <Box sx={{
            width: width || "3px",
            padding: padding || "7px 10px",
            height: height || "25px",
            border: border || "2px solid #333",
            borderRadius: "99px",
            opacity: opacity || "0.75",
            boxSizing: "content-box",
            margin: "auto",
            ...sxOuter,
        }}>
            <Box sx={{
                width: width || "3px",
                height: innerHeight || "10px",
                borderRadius: "25%",
                backgroundColor: "#333",
                animationName: "scroll",
                animationDuration: "2.2s",
                animationTimingFunction: "cubic-bezier(.15,.41,.69,.94)",
                animationIterationCount: "infinite",
                ...sxInner
            }}>
            </Box>
        </Box>
    );
}