import { Link } from 'react-router-dom';
import { Stack, Button, TextField, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import AppBar from '../../helper/AppBar';
import Hint from '../../helper/Hint';
import SignInGithub from './signin-gh/SignInGithub';

  
export default function SignIn(props) {
    const [loading, setLoading] = useState(0);
    const [message, setMessage] = useState(null);
    const theme = useTheme();
    const nav = useNavigate();

    const responseCodes = {
        "access denied": "ReCaptcha flagged this request as spam. Please try again",
        "invalid email": "The email provided does not exist",
        "invalid password": "The password you entered is invalid. Please try again",
        "bad request": "An unknown error occured. Please try again after a few minutes",
    }

    useEffect(() => {
        document.title = "Friday - Sign in";

        fetch("/api/user/current").then(resp => resp.json())
            .then(data => {
                if (data.success) {
                    if (data.result !== null) {
                        nav("/dashboard");
                    }
                }
            }).catch(er => {}).finally(_ => {  });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function signIn() {
        const mail = document.querySelector("#email").value;
        const password = document.querySelector("#password").value;

        setLoading(true);
        
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6Lc1IOsdAAAAAEwsT0oTDFpxloWl-kVvsxDSf1Uh', { action: 'submit' }).then((token) => {
                fetch("/api/user/login", {
                    method: "POST",
                    body: JSON.stringify({
                        mail: mail,
                        password: password,
                        captcha: token
                    })
                }).then(response => response.json())
                .then(data => {
                    if (data.success) {
                        nav("/dashboard");
                    } else {
                        console.log("setMessage()", responseCodes[data.error] || responseCodes["bad request"])
                        setMessage(responseCodes[data.error] || responseCodes["bad request"]);
                    }
                }).finally(_ => {
                    setLoading(false);
                });
            });
        });
    }

    return (
        <div>
            <AppBar></AppBar>

            <Stack spacing={2} direction="column" sx={{ position: "fixed", 
                                                        top: "0px", left: "0px", right: "0px", bottom: "100px", margin: "auto", 
                                                        height: "fit-content",
                                                        maxWidth: "20vw" }}>

                <h1 style={{ position: "absolute", top: "-25px", left: "0px", right: "0px", margin: "auto", padding: "0px", textAlign: "center", fontSize: "20px" }}>Jarvis</h1>

                <Typography component="h2" sx={{ textAlign: "center" }}>Sign into your account</Typography>

                <br />

                <SignInGithub />

                <Divider> or </Divider>

                <br />

                <TextField label="Your e-mail address" inputProps={{ id: "email" }} autoFocus required />
                <TextField label="Your password" inputProps={{ id: "password" }} type="password" required />

                {
                    loading ?
                    <LoadingButton loading sx={{ paddingTop: "14.32px !important", paddingBottom: "14.32px !important" }}>Sign in</LoadingButton> :
                    <Button className="green" onClick={signIn} sx={{ paddingTop: "14.32px !important", paddingBottom: "14.32px !important" }}>Sign in</Button>
                }

                <span style={{ textAlign: "center", fontFamily: theme.typography.fontCode, fontSize: "12px" }}>
                    Need a Jarvis account?&nbsp;
                    <Link to="/signup" style={{ color: theme.palette.text.primary }}>Sign up</Link>
                </span>

                <Hint sx={{ marginTop: 0 }}>
                    This site is protected by reCAPTCHA and the Google&nbsp;
                    <a style={{ color: "#999" }} href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
                    <a style={{ color: "#999" }} href="https://policies.google.com/terms?hl=en-US" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                </Hint>

                {
                    message &&
                    <Typography
                        textAlign={"center"}
                        fontSize={14}
                        sx={{ position: "absolute", bottom: "-80px" }} // to avoid a content jump
                        color={"error"}
                    >{message}</Typography>
                }
            </Stack>
        </div>
    );
}
