import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import NewIntent from "./New";


const rockeet = window.rockeet;


export default function IntentsList({ assistantId, token }) {
    const [intents, setIntents] = useState(null);
    
    rockeet.setToken(token);
    const assistant = new rockeet.Assistant(assistantId);

    useEffect(() => {
        assistant.Intent.list([
            "utterances", "slots", "metadata"
        ]).then(d => {
            setIntents(d);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Typography> Intents: </Typography>
            <Box sx={{  }}>
                {
                    intents?.map(i => {
                        return (
                            <Box sx={{ marginBottom: "20px" }}>
                                <h2>{i.name}</h2>
                                <p>{i.utterances.join("\n")}</p>
                            </Box>
                        );
                    })
                }
            </Box>
            {
                intents?.length === 0 && <>
                    <Typography> No intents found </Typography>
                </>
            }
            <NewIntent assistantId={assistantId} token={token} />
        </>
    );
}