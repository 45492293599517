import { useTheme } from "@emotion/react";
import { Stack, Button, Typography } from "@mui/material";

// import getUserLocale from 'get-user-locale';
import { useNavigate } from "react-router-dom";

export default function PricingOption(props) {
    const theme = useTheme();
    const nav = useNavigate();
    // const locale = getUserLocale().split("-")[0];
    const localeCurrency = "$";

    return (
        <Stack 
            className={props.horizontal ? "pricing-card horizontal" : "pricing-card"} 
            spacing={2} 
            direction={props.horizontal ? "row" : "column"}
            sx={{
                marginLeft: "0px !important",
                display: "grid !important",
                gridTemplateAreas: '"head" "price" "button"',
                gridTemplateRows: "25px 200px 1fr",
                boxShadow: props.currentPlan ? `0px 0px 0px 2px ${theme.palette.success.main} inset` : "none",
                ...props.sx
            }}
        >

            {/* Plan Name */}
            <Typography
                component="h2"
                color="secondary"
                sx={{
                    textAlign: "left",
                    fontSize: "18px"
                }}>
                {props.name} 
            </Typography>

            {/* Description / Pricing */}
            { props.price ? <Stack direction="column">
                <Typography component="span" sx={{ fontWeight: "bold", fontSize: "22px", color: "primary" }}>
                    { typeof props.fee === "string" ? props.fee : localeCurrency + props.fee.toFixed(2)}
                </Typography>
                <Typography component="span" color="secondary" sx={{ fontSize: "14px", marginTop: "0px !important" }}>
                    Per month flat fee for up to {props.requests.toLocaleString(undefined, { minimumFractionDigits: 0 })} requests
                </Typography>
                <Typography component="span" color="primary" sx={{ fontWeight: "bold", fontSize: "22px", marginTop: "20px" }}>
                    + {localeCurrency}{(''+props.price).slice(1)}
                </Typography>
                <Typography component="span" color="secondary" sx={{ fontSize: "14px", marginTop: "0px !important" }}>
                    per additional request above {props.requests.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                </Typography>
            </Stack> : <Stack direction="column">
                <Typography component="span" color="primary" sx={{ fontWeight: "bold", fontSize: "22px" }}>
                    { typeof props.fee === "string" ? props.fee : localeCurrency + props.fee.toFixed(2)}
                </Typography>
                <Typography component="span" color="secondary" sx={{ fontSize: "14px", marginTop: "0px !important" }}>
                    Free for up to {props.requests.toLocaleString(undefined, { minimumFractionDigits: 0 })} requests.<br />API will stop working after the limit has been reached
                </Typography>
            </Stack> }

            {/* Action button */}
            {
                props.current ?
                <Typography color={theme.palette.green.main} textAlign="center" sx={{ padding: "10px 20px" }}>Current plan</Typography>
                : 
                <Button variant="contained" className={props.buttonColor || "green"} {...props.button} onClick={props.onClick || function () {
                    nav(props.to);
                }}> {props.buttonText || "Start free trial"} </Button>
            }
        </Stack>
    ); 
}
