import { Box } from '@mui/material';

import AppBar from 'components/helper/AppBar';
import Sidebar from 'components/pages/dashboard/sidebar/Sidebar';
import SidebarSpacer from 'components/pages/dashboard/sidebar/SidebarSpacer';
import SidebarElement from 'components/pages/dashboard/sidebar/SidebarElement';

import DashboardHome         from 'components/pages/dashboard/DashboardHome';
import DashboardPlan         from 'components/pages/dashboard/DashboardPlan';
import DashboardDocs         from 'components/pages/dashboard/DashboardDocs';
import DashboardFiles        from 'components/pages/dashboard/DashboardFiles';
import DashboardProfile      from 'components/pages/dashboard/DashboardProfile';
import DashboardSupport      from 'components/pages/dashboard/DashboardSupport';
import DashboardApplications from 'components/pages/dashboard/DashboardApplications';

import 'css/dashboard.scss';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';


export default function Dashboard() {
    useEffect(() => {
        document.title = "Friday - Dashboard";
    }, []);

    const urlParams = useParams();
    const page = urlParams.page;
    
    const Page = {
        "undefined": DashboardHome,
        home: DashboardHome,
        profile: DashboardProfile,
        plan: DashboardPlan,
        apps: DashboardApplications,
        files: DashboardFiles,
        contact: DashboardSupport,
        docs: DashboardDocs,
        examples: DashboardFiles,
    }[""+page] || DashboardHome;

    return (
        <>
            <AppBar></AppBar>

            <Sidebar>
                <SidebarElement className={page === "home" || page === undefined ? "active" : ""} icon="home" to="/dashboard/home">Home</SidebarElement>
                <SidebarElement className={page === "profile" ? "active" : ""} icon="account_circle" to="/dashboard/profile">Profile</SidebarElement>
                <SidebarElement className={page === "plan" ? "active" : ""} icon="rocket_launch" to="/dashboard/plan">Subscription</SidebarElement>
                <SidebarElement className={page === "apps" ? "active" : ""} icon="vpn_key" to="/dashboard/apps">Applications</SidebarElement>
                <SidebarElement className={page === "files" ? "active" : ""} icon="save" to="/dashboard/files">Files</SidebarElement>
                <SidebarElement className={page === "contact" ? "active" : ""} icon="contact_support" to="/dashboard/contact">Contact</SidebarElement>

                <SidebarSpacer />

                <SidebarElement className={page === "docs" ? "active" : ""} icon="lightbulb" to="/dashboard/docs">API Docs</SidebarElement>
                <SidebarElement className={page === "examples" ? "active" : ""} icon="flag" to="/dashboard/examples">Examples</SidebarElement>

                <SidebarSpacer />

                <SidebarElement icon="assistant" to="/console">Assistant Console</SidebarElement>
            </Sidebar>

            <Box sx={{ margin: "150px 300px",
                       maxWidth: "calc(100% - 300px)",
                       "& h1": {
                           padding: "0px !important",
                           fontSize: "40px",
                           textAlign: "left"
                       } }}>
                <Page />
            </Box>
        </>
    );
}