import { Box } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Footer from 'components/helper/Footer';

import Index          from 'components/pages/index/index';
import SignUp         from 'components/pages/account/SignUp';
import SignIn         from 'components/pages/account/SignIn';
import Pricing        from 'components/pages/billing/Pricing';
import Subscribe      from 'components/pages/billing/Subscribe';
import ApiDoc         from 'components/pages/docs/ApiDoc';
import Dashboard      from 'components/pages/dashboard/Dashboard';
import Contact        from 'components/pages/misc/Contact';
import PrivacyPolicy  from 'components/pages/legal/PrivacyPolicy';
import TermsOfService from 'components/pages/legal/TermsOfService';
import Imprint        from 'components/pages/legal/Imprint';
import Console        from 'components/pages/assistant/Console';
import AssistantShow  from 'components/pages/assistant/AssistantShow';


function App() {
    return (
        <>
            <BrowserRouter>
                <Box sx={{ minHeight: "100vh" }}>
                    <Routes>
                        <Route path="/" element={<Index />}/>

                        {/* LEGAL */}
                        <Route path="/privacy" element={<PrivacyPolicy />}/>
                        <Route path="/terms" element={<TermsOfService />}/>
                        <Route path="/imprint" element={<Imprint />}/>
                        
                        <Route path="/contact" element={<Contact />}/>

                        <Route path="/signup" element={<SignUp />} /> 
                        <Route path="/signin" element={<SignIn />}/>
                        <Route path="/docs" element={<ApiDoc />}/>

                        <Route path="/dashboard" element={<Dashboard />}/>
                        <Route path="/dashboard/:page" element={<Dashboard />}/>

                        <Route path="/pricing" element={<Pricing />}/>
                        <Route path="/subscribe/:plan" element={<Subscribe />}/>

                        <Route path="/console" element={<Console />}/>
                        <Route path="/console/a/:id" element={<AssistantShow />}/>

                        <Route path="/jarvis-ext" component={() => {
                            window.open("https://jarvis.fipsi.at/?ref=friday", "_blank");
                            return null;
                        }}/>
                    </Routes>
                </Box>
                <Footer />
            </BrowserRouter>
        </>
    );  
};

  

export default App;
