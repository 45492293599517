import { Box, Button, Icon, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";


export default function BetaDisclaimer () {
    const [submittingEmailAddressForProductionNotification, setSubmittingEmailAddressForProductionNotification] = useState(false);
    const [submittingEmailAddressForProductionNotificationSuccess, setSubmittingEmailAddressForProductionNotificationSuccess] = useState(null);
    const [submittingEmailAddressForProductionNotificationMessage, setSubmittingEmailAddressForProductionNotificationMessage] = useState(null);

    return (
        <Stack backgroundColor="orange.main" padding="50px 350px" id="alpha">
            <Typography variant="h1" textAlign="center" fontSize="30px" color="white.main" fontWeight="500" dangerouslySetInnerHTML={{
                __html: "Preview Version (Alpha)"
            }} />

            <Typography fontSize="18px" textAlign="center" color="white.main" margin="50px auto auto auto" width="510px">
                The Friday API is not ready for production yet and therefore only accessible by a small testing group.
                <br />
                You may enter your email address below to be notified when the API is ready for public beta:
            </Typography>

            <form onSubmit={ev => {
                ev.preventDefault();
                const mail = ev.target.elements.email.value;
                setSubmittingEmailAddressForProductionNotification(true);
                fetch("/api/getNotified", {
                    method: "POST",
                    body: JSON.stringify({
                        email: mail
                    })
                }).then(res => res.json()).then(res => {
                    setSubmittingEmailAddressForProductionNotificationSuccess(!!res.success);
                    if (!res.success) {
                        throw new Error(res.error);
                    }
                    ev.target.elements.email.value = "";
                }).catch(er => {
                    setSubmittingEmailAddressForProductionNotificationSuccess(false);
                    setSubmittingEmailAddressForProductionNotificationMessage("Something went wrong when trying to register your email address. <br /> Please try again in a few moments.");
                }).finally(_ => {
                    setTimeout(() => {
                        setSubmittingEmailAddressForProductionNotificationMessage(null);
                        setSubmittingEmailAddressForProductionNotification(false);
                        setSubmittingEmailAddressForProductionNotificationSuccess(null);
                    }, 7500);
                });
            }}>
                <Stack direction="row" space={2} marginTop="50px" justifyContent="space-between" width="500px" margin="50px auto 25px">
                    <TextField required type="email" variant="outlined" name="email" label="Email address" sx={{ width: "60%", marginTop: "0px !important" }}></TextField>
                    <Button type="submit" className="green" disabled={submittingEmailAddressForProductionNotification} variant="contained" padding="16.5px 14px 16.5px 14px !important" sx={{ width: "35%", backgroundColor: "#fff !important" }}>
                        {
                            submittingEmailAddressForProductionNotification ?
                                submittingEmailAddressForProductionNotificationSuccess !== null ?
                                    ( submittingEmailAddressForProductionNotificationSuccess ?
                                        <Stack direction="row">
                                            <Icon baseClassName="material-icons-round" color="success"> check_circle </Icon>
                                            <Typography component="span" fontSize="16px" marginLeft="10px" color="success.main"> Thank you! </Typography>
                                        </Stack>
                                        :
                                        <Stack direction="row">
                                            <Icon baseClassName="material-icons-round" color="error"> error </Icon>
                                            <Typography component="span" fontSize="16px" marginLeft="10px" color="error.main"> Sorry... </Typography>
                                        </Stack>
                                        )
                                    :
                                <Stack direction="row">
                                    <Icon baseClassName="material-icons-round" color="info" sx={{ animation: "rotate linear 1s infinite" }}> cached </Icon>
                                    <Typography component="span" fontSize="16px" marginLeft="10px" color="info.main"> Submitting... </Typography>
                                </Stack>
                            :
                            <Stack direction="row">
                                <Icon baseClassName="material-icons-round" color="primary"> notifications </Icon>
                                <Typography component="span" fontSize="16px" marginLeft="10px" color="primary.main"> Get notified! </Typography>
                            </Stack>
                        }
                    </Button>
                </Stack>

                <Box position="relative">
                    <Box position="absolute" top="0px" left="0px" right="0px" bottom="0px" zIndex={99}>
                        {
                            submittingEmailAddressForProductionNotificationMessage && 
                            <Typography fontSize="16px" textAlign="center" color="white.main" width="500px" margin="0px auto" padding="20px 40px" sx={{ backgroundColor: "error.main" }}
                            dangerouslySetInnerHTML={{ __html: submittingEmailAddressForProductionNotificationMessage }} />
                        }
                    </Box>
                </Box>
            </form>
        </Stack>
    );
}